import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { InvitationService } from 'src/app/shared/services/invitation.service';

import { Store } from '@ngrx/store';
import * as invitationState from 'src/app/store/invitation-store/invitation.state';
import { saveInvitationDetailsRequestAction } from 'src/app/store/invitation-store/actions';

import { IInvitation } from 'src/app/shared/models/invitation';

@Injectable({
  providedIn: 'root'
})
export class PreApplicationGuard implements CanActivate {

  constructor(
    private router: Router,
    private invitationService: InvitationService,
    private invitationStore: Store<invitationState.InvitationState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const id = next && next.params && next.params.id;

    return this.checkInvitation(id);
  }

  /**
   * Check invitation id data
   * - valid id
   * -- not found
   * -- expired
   * - isFullApplication = true
   * - is FullApplication = false
   * - TODO: it has application created (not sure if needed anymore)
   */

  /**
   * Checks if the invitation id is valid
   * @param error - http error response object
   */
  handleInvalidInvitation(error: HttpErrorResponse): boolean {
    /**
     * -- expired (error status 500)
     * --- redirect to expired page
     */
    if (error && error.status === 500 && error.error.errors[0].title === 'Invitation expired') {
      this.router.navigate(['expired']).then(() => false);
    } else {
      this.router.navigate(['/']).then(() => false);
    }
    return false;
  }

  /**
   * Check if the invitation is for full application
   * Store invitation for register page
   * and redirect to authentication page
   * @param invitation - invitation details
   */
  isFullApplicationInvite(invitation: IInvitation): boolean {
    if (!!invitation.attributes.isFullApplicationInvitation) {
      /** Store invitation */
      this.invitationStore.dispatch(saveInvitationDetailsRequestAction({invitation}));
      this.router.navigate(['register']).then(() => false);
      return false;
    }
    return true;
  }

  /**
   * Check Invitation
   * @param id - invitation id
   */
  checkInvitation(id: string) {
    return this.invitationService.getInvitation(id).pipe(
      map(result => {
        return this.isFullApplicationInvite(result.data);
      }),
      catchError(error => of(this.handleInvalidInvitation(error)))
    );
  }
}
