import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './org-details-reducer';
import { OrganizationStoreEffects } from './org-details.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('organization-details', reducer),
    EffectsModule.forFeature([OrganizationStoreEffects])
  ]
})
export class OrganizationDetailsStoreModule { }
