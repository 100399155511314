import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { get } from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { SelfServiceService } from 'src/app/shared/services/self-service.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceGuard implements CanActivate {
  constructor(private selfServiceService: SelfServiceService, private router: Router) {
  }

  handleInvalidData(error: HttpErrorResponse) {
    /**
     * -- not found (error status = 0 from 'OPTIONS' req type) or 404
     * --- redirect to not found page
     * -- expired (error status 500)
     * --- redirect to expired page
     */
    if (error && error.status === 0 || error.status === 404) {
      this.router.navigate(['/']).then(() => false);
    }
    if (error && error.status === 500 && error.error.errors[0].title === 'Invitation expired') {
      this.router.navigate(['expired']).then(() => false);
    }
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const dossierId = get(route, 'params.dossierId');
    const magicToken = get(route, 'params.magicToken');
    return this.selfServiceService.geReservation(dossierId, magicToken).pipe(
      map(() => {
        return true;
      }),
      catchError(error => of(this.handleInvalidData(error))),
    );
  }
}
