import { IOrganizationDetailsRequest } from 'src/app/shared/models/organization-details';
import { IDomain } from 'src/app/shared/models/portal-domain';

export interface OrganizationDetailsState {
  isLoading: boolean;
  details: IOrganizationDetailsRequest;
  error: any;
  domains: IDomain;
  domainsLoading: boolean;
}

const organizationDetails: IOrganizationDetailsRequest = {
  data: {
    id: null,
    type: null,
    attributes: {
      accountId: null,
      createdAt: null,
      settings: null,
      updatedAt: null,
      applicationFormSettings: {
        attachmentsRules: {
          manual: null,
          ockto: null,
        }
      },
    },
  },
  meta: null
};

export const initialState: OrganizationDetailsState = {
  isLoading: false,
  details: organizationDetails,
  error: null,
  domains: null,
  domainsLoading: true,
};

