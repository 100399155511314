<div class="row g-0 d-flex justify-content-center">
  <div class="col-lg-6">
    <div class="jumbotron p-3 mb-0 text-center rounded-0"
         [ngStyle]='{background: "url(" + headerImage +  ") no-repeat center #dddddd", "background-size": "cover"}'>
      <osre-navigation></osre-navigation>
      <div class="text-center text-white">
        <h1 class="display-5">{{title}}</h1>
        <p class="lead fw-normal" *ngIf="address">{{address}}</p>
      </div>
    </div>
  </div>
</div>
