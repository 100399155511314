import { createAction, props } from '@ngrx/store';
import { LoadingActionTypes } from 'src/app/store/invitation-store/invitation-types';

export const startInvitationLoadingAction = createAction(
  LoadingActionTypes.START_LOADING
);

export const finishInvitationLoadingAction = createAction(
  LoadingActionTypes.FINISH_LOADING
);

export const setInvitationErrorAction = createAction(
  LoadingActionTypes.SET_ERROR,
  props<{ payload: { error: any } }>()
);
