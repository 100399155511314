import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes } from 'src/app/store/auth-store/auth-types';

export const loginRequestAction = createAction(
  RequestActionTypes.LOGIN_REQUEST,
  props<{ credentials: { email: string, password: string} }>()
);

export const loginSuccessAction = createAction(
  SuccessActionTypes.LOGIN_SUCCESS,
  props<{ payload: { isAuthenticated: boolean, user: any} }>()
);

export const logoutRequestAction = createAction(
  RequestActionTypes.LOGOUT_REQUEST,
);

export const logoutSuccessAction = createAction(
  SuccessActionTypes.LOGOUT_SUCCESS,
  props<{ payload: { isAuthenticated: boolean, user: null} }>()
);

export const passwordResetRequestAction = createAction(
  RequestActionTypes.PASSWORD_RESET_REQUEST,
  props<{ email: string }>()
);

export const passwordResetSuccessAction = createAction(
  SuccessActionTypes.PASSWORD_RESET_SUCCESS,
  props<{ success: boolean }>()
);

export const passwordChangeRequestAction = createAction(
  RequestActionTypes.PASSWORD_CHANGE_REQUEST,
  props<{ payload: { password: string, passwordConfirm: string, token: string } }>()
);

export const passwordChangeSuccessAction = createAction(
  SuccessActionTypes.PASSWORD_CHANGE_SUCCESS,
  props<{ success: boolean }>()
);
