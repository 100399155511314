import { Action, createReducer, on } from '@ngrx/store';
import { initialState, DocumentlessState } from './documentless.state';
import * as DocumentlessActions from './actions';

const documentlessReducer = createReducer(initialState,
  on(DocumentlessActions.getQrCodeRequestAction, state => ({ ...state, qrCodeLoading: true, qrCodeError: null })),
  on(DocumentlessActions.getQrCodeSuccessAction, (state, { payload, forPartner }) => ({
    ...state,
    qrCodeLoading: false,
    ...(forPartner ? { partnerQrCodeImage: payload } : { qrCodeImage: payload }),
  })),
  on(DocumentlessActions.getQrCodeFailure, (state, { payload }) => ({ ...state, qrCodeError: payload.error, qrCodeLoading: false })),
  on(DocumentlessActions.getApplicationLinkRequestAction, state => ({
    ...state,
    applicationLinkLoading: true,
    applicationLinkError: null,
  })),
  on(DocumentlessActions.getApplicationLinkSuccessAction,
    (state, { payload, forPartner }) => ({
      ...state,
      applicationLinkLoading: false,
      ...(forPartner ? { partnerApplicationLink: payload } : { applicationLink: payload }),
    })),
  on(DocumentlessActions.getApplicationLinkFailureAction,
    (state, { payload }) => ({ ...state, applicationLinkLoading: false, applicationLinkError: payload.error })),
  on(DocumentlessActions.getAvailabilityRequestAction, state => ({
    ...state,
    isAvailableLoading: true,
    isAvailableError: null,
    isAvailable: null,
  })),
  on(DocumentlessActions.getAvailabilitySuccessAction, (state, { payload }) => ({
    ...state,
    isAvailableLoading: false,
    isAvailable: payload,
  })),
  on(DocumentlessActions.getAvailabilityFailureAction,
    (state, { payload }) => ({ ...state, isAvailableLoading: false, isAvailableError: payload.error, isAvailable: false })),
  on(DocumentlessActions.getFinancialSessionSuccessAction, (state, { payload }) => (
    {
      ...state,
      financialSession: payload,
      financialSessionLoading: false,
      sessionNotInitiatedForCandidate: false,
    })),
  on(DocumentlessActions.getFinancialSessionErrorAction, (state, { error }) => (
    {
      ...state,
      financialSessionError: error,
      financialSessionLoading: false,
      sessionNotInitiatedForCandidate: error.status === 404 ? true : state.sessionNotInitiatedForCandidate,
    })),
  on(DocumentlessActions.getFinancialSessionRequestAction, (state) => (
    {
      ...state,
      financialSessionError: null,
      financialSessionLoading: true,
      sessionNotInitiatedForCandidate: false,
    })),
  on(DocumentlessActions.setFinancialSession, (state, { financialSession }) => ({ ...state, financialSession })),
  on(DocumentlessActions.setSessionCompletedOneTimeOnly, (state) => ({
    ...state,
    sessionCompletedOneTimeOnly: true,
  })),
  on(DocumentlessActions.clearState, (state) => ({
    ...initialState,
  })),
  on(DocumentlessActions.clearQrCodeSuccessAction, (state) => ({
    ...state,
    qrCodeImage: '',
  })),
  on(DocumentlessActions.getActiveProvider, (state) => ({ ...state, activeProviderLoading: true })),
  on(DocumentlessActions.getActiveProviderByToken, (state) => ({ ...state, activeProviderLoading: true })),
  on(DocumentlessActions.getActiveProviderSuccess, (state, action) => ({
    ...state, activeProvider: action.activeProvider, activeProviderLoading: false,
  })),
  on(DocumentlessActions.getActiveProviderFailure, (state) => ({ ...state, activeProviderLoading: false })),
);

export function reducer(state: DocumentlessState | undefined, action: Action) {
  return documentlessReducer(state, action);
}
