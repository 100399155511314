export enum RequestActionTypes {
  GET_ACCOUNT_SETTINGS_REQUEST = '[Account Settings] Get Account Settings Details Request',
  GET_ACCOUNT_SETTINGS_FOR_ROOT_REQUEST = '[Account Settings] Get Account Settings Details For Root Request',
  GET_PORTAL_DOMAINS_REQUEST = '[Account Settings] Get Portal Domains Request',
  GET_ALL_PORTAL_DOMAINS_REQUEST = '[Account Settings] Get All Portal Domains Request',
}

export enum SuccessActionTypes {
  GET_ACCOUNT_SETTINGS_SUCCESS = '[Account Settings] Get Account Settings Success',
  GET_ACCOUNT_SETTINGS_FOR_ROOT_SUCCESS = '[Account Settings] Get Account Settings For Root Success',
  GET_PORTAL_DOMAINS_SUCCESS = '[Account Settings] Get Portal Domains Success',
}

export enum FailureActionTypes {
  GET_ACCOUNT_SETTINGS_FAILURE = '[Account Settings] Get Account Settings Failure',
  GET_ACCOUNT_SETTINGS_FOR_ROOT_FAILURE = '[Account Settings] Get Account Settings For Root Failure',
  GET_PORTAL_DOMAINS_FAILURE = '[Account Settings] Get Portal Domains Failure',
}

export enum LoadingActionTypes {
  START_LOADING = '[Org Details Loading] Start loading',
  FINISH_LOADING = '[Org Details  Loading] Finish loading',
  SET_ERROR = '[Org Details  Error] Set error',
}
