import { Directive, ElementRef, AfterContentInit } from '@angular/core';

  @Directive({
      selector: '[osreAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}
  ngAfterContentInit() {
        this.el.nativeElement.focus();
      }
}
