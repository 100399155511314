import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IInvitationRequest } from '../models/invitation';
import {
  ApplicationFileRequest,
  IApplication,
  IApplicationFull,
  IProspectApplication,
} from 'src/app/shared/models/application';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(private apiService: ApiService) { }

  getInvitation(id: string): Observable<IInvitationRequest> {
    return this.apiService.getSingle('relet',`/portal/invitations/${id}`);
  }

  sendApplication(application: IApplication) {
    return this.apiService.save('relet', '/portal/applications/pre-application', application);
  }

  sendProspectApplication(prospectApplication: IProspectApplication) {
    return this.apiService.save('relet', '/portal/applications/prospect-application', prospectApplication);
  }

  updateApplication(application: IApplicationFull, id: string) {
    const params = {
      application: { questionnaire: application.application, verifiedQuestionnaire: null },
      hasPreApplication: null,
      applicationProgress: null,
    };
    return this.apiService.update('relet', `/portal/applications/${id}`, params);
  }

  saveApplicationFile(applicationId: string, file: ApplicationFileRequest) {
    const data = new FormData();
    data.append('fileUpload', file.fileUpload);
    data.append('personId', file.personId);
    data.append('type', file.type);
    return this.apiService.save('relet', `/portal/applications/${applicationId}/files`, data);
  }

  deleteApplicationFile(applicationId: string, attachmentId: string) {
    return this.apiService.delete('relet', `/portal/applications/${applicationId}/files/${attachmentId}`);
  }
}
