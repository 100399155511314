import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes } from 'src/app/store/invitation-store/invitation-types';
import { IInvitation } from 'src/app/shared/models/invitation';

export const getInvitationRequestAction = createAction(
  RequestActionTypes.GET_INVITATION_REQUEST,
  props<{ id: string }>(),
);

export const getInvitationSuccessAction = createAction(
  SuccessActionTypes.GET_INVITATION_SUCCESS,
  props<{ payload: IInvitation }>(),
);

export const saveInvitationDetailsRequestAction = createAction(
  RequestActionTypes.SAVE_INVITATION_DETAILS_REQUEST,
  props<{ invitation: IInvitation }>(),
);

export const saveInvitationDetailsSuccessAction = createAction(
  SuccessActionTypes.SAVE_INVITATION_DETAILS_SUCCESS,
  props<{ payload: IInvitation }>(),
);

export const redirectTo = createAction('[Invitation API] Redirect To Action', props<{ url: string }>());
