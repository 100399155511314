import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { switchMap, catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthenticationService } from './authentication.service';
import * as state from 'src/app/store/auth-store/auth.state';
import { logoutRequestAction } from 'src/app/store/auth-store/actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private authService: AuthenticationService,
    private router: Router,
    private authenticationStore: Store<state.AuthState>) {}

  /**
   * Intercept HTTP Requests and handle getToken error
   * @param request http request
   * @param next http handler
   * @return observable
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthenticationService);

    return authService.getToken()
      .pipe(switchMap((token) => {
        if (token) {
          return next.handle(request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          })).pipe(catchError((err) => {
            return this.catchError(err);
          }));
        } else {
          return next.handle(request).pipe(catchError((err) => {
            return this.catchError(err);
          }));
        }
      }));
  }

  /**
   * Catch error
   * @param err error
   */
  private catchError(err) {

    const authService = this.injector.get(AuthenticationService);
    const router = this.injector.get(Router);
    // const state: RouterStateSnapshot = router.routerState.snapshot;

    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        this.authenticationStore.dispatch(logoutRequestAction());
      }
      return observableThrowError(err);
    }
  }
}
