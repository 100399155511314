import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './auth-reducer';
import { AuthStoreEffects } from './auth-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature([AuthStoreEffects])
  ]
})
export class AuthStoreModule { }
