import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'osre-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: ['./confirmation-message.component.scss']
})
export class ConfirmationMessageComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() iconClass: string;

  constructor() { }

  ngOnInit() {
    this.setDefaults();
  }

  private setDefaults() {
    this.iconClass = this.iconClass || 'fas fa-check';
    this.title = this.title || 'Title';
    this.message = this.message || 'Confirmation message description';
  }

}
