import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';

@Component({
  selector: 'osre-appointment-confirm-page',
  templateUrl: './appointment-confirm-page.component.html',
  styleUrls: ['./appointment-confirm-page.component.scss'],
})
export class AppointmentConfirmPageComponent implements OnInit {
  isAppointmentInvitationAccepted: boolean;
  appointmentId: string;
  appointment: any;
  errors: any;

  constructor(private activatedRoute: ActivatedRoute, private appointmentsService: AppointmentsService) {
    const {appointmentData} = this.activatedRoute.snapshot.data;
    const {appointmentId} = this.activatedRoute.snapshot.params;
    this.appointmentId = appointmentId;
    this.isAppointmentInvitationAccepted = appointmentData?.isAppointmentInvitationAccepted;
    this.appointment = appointmentData?.appointment;
    if (appointmentData.errors) {
      this.errors = appointmentData.errors;
    }
  }

  ngOnInit(): void {
  }

  openGoogleCalendar(appointment) {
    const appointmentAttributes = appointment.attributes;
    const params: any = {
      action: 'TEMPLATE',
      text: appointmentAttributes.title,
      dates: `${
        moment(appointmentAttributes.startDate).format('YYYYMMDDTHHmmss')}/${moment(appointmentAttributes.endDate).format('YYYYMMDDTHHmmss')}`,
      location: appointmentAttributes.location,
    };

    params.details = '';
    // appointmentAttributes.candidates.forEach(function (candidate) {
    //   params.details = params.details + '\n' + 'Name: ' + candidate.candidateName + (candidate.candidatePhoneNumber !== null ? ('\nPhone number: ' + candidate.candidatePhoneNumber) : '');
    // });

    if (appointmentAttributes.message) {
      params.details = params.details + '\n' + appointmentAttributes.message;
    }

    params.details = encodeURIComponent(params.details);

    if (appointmentAttributes.location) {
      params.location = appointmentAttributes.location;
    }
    const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    window.open(`https://www.google.com/calendar/r/eventedit?${queryString}`, '_blank');
  }

  downloadICalFile(appointmentId) {
    const download = response => saveAs(new Blob([response], {type: 'text/calendar; charset=utf-8'}));
    this.appointmentsService.getAppointmentICalFile(appointmentId).subscribe(download);
  }
}
