import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsResolver implements Resolve<boolean> {
  constructor(private appointmentsService: AppointmentsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const { action, appointmentId, magicToken } = route.params;
    return this.appointmentsService.getAppointmentRespondToInvite(appointmentId, action, magicToken)
      .pipe(
        map(result => ({
          isAppointmentInvitationAccepted: action === 'accept',
          appointment: result?.data,
        })),
        catchError(errorResponse => of({ errors: errorResponse?.error?.errors })),
      );
  }
}
