export enum RequestActionTypes {
  LOGIN_REQUEST = '[Auth Api] Login Request',
  LOGOUT_REQUEST = '[Auth Api] Logout Request',
  GET_USER_DETAILS_REQUEST = '[Auth Api] Get User Details Request',
  REGISTER_USER_REQUEST = '[Auth Api] Register User Request',
  CONFIRM_USER_REQUEST = '[Auth Api] Confirm User Request',
  PASSWORD_RESET_REQUEST = '[Auth Api] Password Reset Request',
  PASSWORD_CHANGE_REQUEST = '[Auth Api] Password Change Request',
}

export enum SuccessActionTypes {
  LOGIN_SUCCESS = '[Auth Api] Login Success',
  LOGOUT_SUCCESS = '[Auth Api] Logout Success',
  GET_USER_DETAILS_SUCCESS = '[Auth Api] Get User Details Success',
  REGISTER_USER_SUCCESS = '[Auth Api] Register User Success',
  CONFIRM_USER_SUCCESS = '[Auth Api] Confirm User Success',
  PASSWORD_RESET_SUCCESS = '[Auth Api] Password Reset Success',
  PASSWORD_CHANGE_SUCCESS = '[Auth Api] Password Change Success',

}

export enum FailureActionTypes {
  LOGIN_FAILURE = '[Auth Api] Login Failure',
  GET_USER_DETAILS_FAILURE = '[Auth Api] Get User Details Failure',
}

export enum LoadingActionTypes {
  START_LOADING = '[Auth Loading] Start loading',
  FINISH_LOADING = '[Auth Loading] Finish loading',
  SET_ERROR = '[Auth Error] Set error',
}
