import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar, private translateService: TranslateService) {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      if (evt.type === 'VERSION_READY') {
        const updateSnackBar = this.snackBar.open(
          this.translateService.instant('A new update is available. Please reload the page to see the changes'),
          this.translateService.instant('Reload'),
          {
            panelClass: ['bg-info', 'text-white'],
            politeness: 'polite',
          });
        updateSnackBar.onAction().subscribe(() =>
          swUpdate.activateUpdate().then(() => document.location.reload()));
      }
    });
  }
}
