import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { environment } from 'src/environments/environment';
import { CustomSerializer } from './router-store';
import { InvitationStoreModule } from './invitation-store';
import { AuthStoreModule } from './auth-store';
import { OrganizationDetailsStoreModule } from './organization-store';
import { DocumentlessStoreModule } from './documentless-store';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InvitationStoreModule,
    AuthStoreModule,
    OrganizationDetailsStoreModule,
    DocumentlessStoreModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    EffectsModule.forRoot([]),
  ]
})
export class RootStoreModule { }
