export enum RequestActionTypes {
  GET_QR_CODE_REQUEST = '[Documentless API] Get QR Code Request',
  GET_APPLICATION_LINK_REQUEST = '[Documentless API] Get Application Link Request',
  GET_AVAILABILITY_REQUEST = '[Documentless API] Get Availability Request',
  GET_FINANCIAL_SESSION_REQUEST = '[Documentless API] Get Financial Session Request'
}

export enum SuccessActionTypes {
  GET_QR_CODE_SUCCESS = '[Documentless API] Get QR Code Success',
  GET_APPLICATION_LINK_SUCCESS = '[Documentless API] Get Application Link Success',
  GET_AVAILABILITY_SUCCESS = '[Documentless API] Get Availability Success',
  GET_FINANCIAL_SESSION_SUCCESS = '[Documentless API] Get Financial Session Success',
  SET_FINANCIAL_SESSION_COMPLETED = '[Documentless API] Set Financial Session Completed',
  CLEAR_DOCUMENTLESS_STATE = '[Documentless API] Clear Store State',
  CLEAR_QR_CODE_SUCCESS = '[Documentless API] Clear QR Code'
}

export enum FailureActionTypes {
  GET_QR_CODE_FAILURE = '[Documentless API] Get QR Code Failure',
  GET_APPLICATION_LINK_FAILURE = '[Documentless API] Get Application Link Failure',
  GET_AVAILABILITY_FAILURE = '[Documentless API] Get Availability Failure',
  GET_FINANCIAL_SESSION_ERROR = '[Documentless API] Get Financial Session Error'
}
