<nav class="navbar navbar-expand-xl shadow-sm navbar-light bg-white rounded mb-3 px-3">
  <a class="navbar-brand py-0" [routerLink]="['dashboard']">
    <img class="logo d-inline-block align-top" *ngIf="logo" [src]="logo" alt="logo">
  </a>

  <button class="navbar-toggler" type="button" data-bs-toggle="modal" data-bs-target="#modal" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Modal -->
  <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="user details" aria-hidden="true">
    <div class="modal-dialog mt-3" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-start">

            <!-- Logged in user -->
            <div
              *ngIf="user"
              class="d-inline-flex align-items-center justify-content-between candidate-icon"
              [ngClass]="{'loading': isLoading$ | async}"
            >

              <span class="user-icon rounded-circle border bg-light me-2"><i class="far fa-user-circle"></i></span>

              <div *ngIf="!(isLoading$ | async)">
                <p class="mb-0">{{user.name}}</p>
                <small class="text-muted">{{user.email}}</small>
              </div>
            </div>

          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-start text-muted pb-0">
          <ng-container *ngIf="user">
            <div class="row">
              <div class="col">
                <a class="text-dark" [routerLink]="['dashboard']" data-bs-dismiss="modal" translate>Dashboard</a>
              </div>
            </div>
            <div class="row d-block py-3">
              <mat-divider></mat-divider>
            </div>
          </ng-container>
          <div class="row pb-3">
            <div class="col d-flex">
              <osre-language-switcher class="me-3"></osre-language-switcher>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start bg-light border-top-0" (click)="logout()" data-bs-dismiss="modal">
          <span class="me-2"><i class="fas fa-sign-out-alt"></i></span>
          <button type="button" class="btn btn-link text-dark p-0" translate>Log out</button>
        </div>
      </div>
    </div>
  </div>

  <!-- DESKTOP NAVIGATION LIST -->
  <div class="collapse navbar-collapse flex-wrap-reverse" id="navbar">
    <ng-container *ngIf="user; else noUserTemplate">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['dashboard']" translate>Dashboard</a>
        </li>
      </ul>
      <div
        class="d-inline-flex align-items-center justify-content-between candidate-icon me-2 text-start"
        [ngClass]="{'loading': isLoading$ | async}"
      >
        <osre-language-switcher class="me-3"></osre-language-switcher>
        <div class="d-flex user-information-container">
          <div>
            <span class="user-icon rounded-circle border bg-light me-2"><i class="far fa-user-circle"></i></span>
          </div>

          <div *ngIf="!(isLoading$ | async)" class="d-flex flex-column">
            <p class="mb-0">{{user.name}}</p>
            <small class="text-muted">{{user.email}}</small>
          </div>
        </div>
        <span class="ms-2"><i class="fas fa-sign-out-alt" (click)="logout()"></i></span>
      </div>
    </ng-container>
    <ng-template #noUserTemplate>
      <div class="d-inline-flex align-items-center justify-content-between candidate-icon ms-auto me-2 text-start">
        <osre-language-switcher></osre-language-switcher>
      </div>
    </ng-template>
  </div>
</nav>
