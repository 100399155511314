import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
// @ts-ignore
import en from 'src/assets/i18n/en-GB.json';
// @ts-ignore
import nl from 'src/assets/i18n/nl-NL.json';
// @ts-ignore
import de from 'src/assets/i18n/de-DE.json';

export class TranslateFileLoader extends TranslateLoader {
  constructor() {
    super();
  }

  /**
   * Get the translation
   */
  getTranslation(lang: string): Observable<any> {
    // The default language is preloaded to avoid translation loading delay.
    return {
      'en-GB': of<any>(en),
      'nl-NL': of<any>(nl),
      'de-DE': of<any>(de),
    }[lang] || of<any>(nl);
  }
}
