<div [formGroup]="postalCodeForm">
  <div [ngClass]="{'mb-3': control.invalid}">
    <mat-form-field class='w-100' [ngClass]="{'mb-3': control.invalid && (control.dirty || control.touched)}">
      <input matInput placeholder="{{placeholder}}" formControlName="control" #inputElement [errorStateMatcher]="errorMatcherInput"/>
      <div class="position-absolute" *ngIf="control.invalid && (control.dirty || control.touched)">
        <p *ngIf="control.hasError('pattern')" class="error-message mt-3 mb-0" translate>Invalid postal code. Example: {{placeholderExample}}</p>
        <p *ngIf="control.hasError('required')" class="error-message mt-3 mb-0" translate>This field is required.</p>
      </div>
      <mat-error>
        <ng-content></ng-content>
      </mat-error>
    </mat-form-field>

  </div>
</div>
