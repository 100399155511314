import { Action, createReducer, on } from '@ngrx/store';
import * as invitationActions from './actions';
import { initialState, InvitationState } from './invitation.state';
import { filter } from 'lodash';
import { IApplicationList } from 'src/app/shared/models/application-list';

const invitationReducer = createReducer(
  initialState,
  on(invitationActions.startInvitationLoadingAction, state => ({...state, error: null, isLoading: true})),
  on(invitationActions.finishInvitationLoadingAction, state => ({...state, isLoading: false})),
  on(invitationActions.setInvitationErrorAction, (state, {payload}) => ({
    ...state,
    error: payload.error,
    isLoading: false
  })),
  on(invitationActions.getInvitationRequestAction, state => ({...state, error: null, isLoading: true})),
  on(invitationActions.getInvitationSuccessAction, (state, action) => ({
    ...state, invitation: action.payload, isLoading: false, error: null
  })),
  on(invitationActions.saveInvitationDetailsRequestAction, state => ({...state, error: null, isLoading: true})),
  on(invitationActions.saveInvitationDetailsSuccessAction, (state, action) => ({
    ...state, invitation: action.payload, isLoading: false, error: null
  })),
  on(invitationActions.saveProspectApplicationRequestAction, state => ({...state, error: null, isLoading: true})),
  on(invitationActions.saveProspectApplicationSuccessAction, (state, action) => ({
    ...state, prospectApplication: action.payload, isLoading: false, error: null
  })),
  on(invitationActions.savePreApplicationDraftRequestAction, state => ({...state, error: null, isLoading: true})),
  on(invitationActions.savePreApplicationDraftSuccessAction, (state, action) => ({
    ...state, application: action.payload, isLoading: false, error: null
  })),
  on(invitationActions.getApplicationListRequestAction, state => ({ ...state, error: null, isLoading: true})),
  on(invitationActions.getApplicationListSuccessAction, ((state, action) => {
    /*
     * Filter out pre applications
     * 'awaiting_full_application'
     * 'full_application_completed'
     */
    const applications = filter(action.payload, (application: IApplicationList) => {
      return application.attributes.application.status === 'awaiting_full_application' ||
        application.attributes.application.status === 'full_application_completed';
    });
    return {
      ...state,
      applicationList: applications,
      selectedApplication: null,
      isLoading: false,
      error: null
    };
  })),
  on(invitationActions.getApplicationByIdRequestAction, state => (
    { ...state, error: null, selectedApplication: null, isLoading: true, filesUploaded: false })),
  on(invitationActions.getApplicationByIdSuccessAction, ((state, action) => ({
      ...state,
      selectedApplication: action.application,
      isLoading: false,
      error: null,
    })
  )),
  on(invitationActions.saveFilesSuccess, ((state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      filesUploaded: action.filesUploaded
    };
  })),
  on(invitationActions.deleteFileSuccess, (state, action) => ({
      ...state,
      selectedApplication: {
        ...state.selectedApplication,
        attributes: {
          ...state.selectedApplication.attributes,
          attachments: filter(state.selectedApplication.attributes.attachments,
            attachment => attachment.attachmentId !== action.attachmentId),
        },
      },
    }),
  ),
  on(invitationActions.saveApplicationSectionRequest, state => ({
    ...state,
    success: false,
  })),
  on(invitationActions.saveApplicationSectionSuccess, ((state, action) => {
    return action.redirect && !action.updateState ? state : {
      ...state,
      error: null,
      success: true,
      selectedApplication: state.selectedApplication ? {
        ...state.selectedApplication,
        attributes: {
          ...state.selectedApplication.attributes,
          questionnaire: action.data.attributes.questionnaire
        }
      } : state.selectedApplication
    };
  })),
  on(invitationActions.saveApplicationSectionError, (state, { error }) => ({
    ...state,
    error,
    success: false,
  })),
);

export function reducer(state: InvitationState | undefined, action: Action) {
  return invitationReducer(state, action);
}
