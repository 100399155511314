import * as fromRouter from '@ngrx/router-store';

export interface State {
  state: any;
  router: fromRouter.RouterReducerState<any>;
}

// export const initialState: State = {
//   router: {
//     state: {
//       url: null,
//       params: null,
//       queryParams: null
//     },
//     navigationId: null
//   }
// };
