import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Observable, of } from 'rxjs';
import { IApplicationListRequest } from 'src/app/shared/models/application-list';
import { IApplicationFinalize, IApplicationRequest, IPerson } from 'src/app/shared/models/application';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public defaultPersonQuestionnaire: IPerson = {
    personalDetails: {
      initials: null,
      firstName: null,
      insertion: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      dateOfBirth: null,
      gender: null,
      livingSituation: null,
    },
    address: {
      country: null,
      street: null,
      houseNumber: null,
      houseNumberExtension: null,
      postalCode: null,
      city: null,
    },
    idDocument: {
      idDocumentType: null,
      idDocumentNumber: null,
      idIssueDate: null,
      idExpirationDate: null,
      idIssueCountry: null,
      cityOfBirth: null,
    },
    workSituation: {
      workSituation: null,
      workMonthlySalary: 0,
    },
    employment: {
      employerName: null,
      workJobTitle: null,
      employerContactName: null,
      employerPhoneNumber: null,
    },
    financialSituation: {
      financialSavings: 0,
      financialCredits: 0,
      bankName: null,
      bankAccount: null,
      otherBankName: null,
      otherBankAccount: null,
      annualIncomeYear: null,
      annualIncome: 0,
    },
  };

  constructor(private api: ApiService) { }

  getApplications(): Observable<IApplicationListRequest> {
    return this.api.getAll('relet', '/portal/applications');
  }

  getApplicationById(id: string, magicToken: string | null = null): Observable<IApplicationRequest> {
    if(magicToken){
      return this.api.getSingle('relet', `/portal/applications/${id}/${magicToken}`);
    }
    return this.api.getSingle('relet', `/portal/applications/${id}`);
  }

  finalizeApplication(id: string, application: IApplicationFinalize): Observable<any> {
    return this.api.update('relet', `/portal/applications/${id}/finalize`, application);
  }
}
