export enum RequestActionTypes {
  GET_INVITATION_REQUEST = '[Invitation API] Get Invitation Request',
  SAVE_INVITATION_DETAILS_REQUEST = '[Invitation API] Save Invitation Details Request',
  SAVE_PRE_APPLICATION_REQUEST = '[Invitation API] Save Pre Application Request',
  SAVE_PROSPECT_APPLICATION_REQUEST = '[Invitation API] Save Prospect Application Request',
  GET_APPLICATION_LIST_REQUEST = '[Application List API] Get Application List Request',
  SAVE_SELECTED_APPLICATION_REQUEST = '[Application List API] Save Selected Application Request',
  GET_SELECTED_APPLICATION_REQUEST = '[Application List API] Get Selected Application Request',
  FINALIZE_FULL_APPLICATION_REQUEST = '[Application API] Finalize Full Application Request',
  SAVE_APPLICATION_FILES_REQUEST = '[Application Files API] Save Application Files Request',
  SAVE_APPLICATION_SECTION_REQUEST = '[Application API] Save Application Section Request',
  DELETE_APPLICATION_FILE_REQUEST = '[Application Files API] Delete Application File Request',
}

export enum SuccessActionTypes {
  GET_INVITATION_SUCCESS = '[Invitation API] Get Invitation Success',
  SAVE_INVITATION_DETAILS_SUCCESS = '[Invitation API] Save Invitation Details Success',
  SAVE_PRE_APPLICATION_SUCCESS = '[Invitation API] Save Pre Application Success',
  SAVE_PROSPECT_APPLICATION_SUCCESS = '[Invitation API] Save Prospect Application Success',
  GET_APPLICATION_LIST_SUCCESS = '[Application List API] Get Application List Success',
  SAVE_SELECTED_APPLICATION_SUCCESS = '[Application List API] Save Selected Application Success',
  GET_SELECTED_APPLICATION_SUCCESS = '[Application List API] Get Selected Application Success',
  FINALIZE_FULL_APPLICATION_SUCCESS = '[Application API] Finalize Full Application Success',
  SAVE_APPLICATION_FILES_SUCCESS = '[Application Files API] Save Application Files Success',
  SAVE_APPLICATION_SECTION_SUCCESS = '[Application API] Save Application Section Success',
  DELETE_APPLICATION_FILE_SUCCESS = '[Application Files API] Delete Application File Success'
}

export enum FailureActionTypes {
  GET_INVITATION_FAILURE = '[Invitation API] Get Invitation Failure',
  SAVE_PRE_APPLICATION_FAILURE = '[Invitation API] Save Pre Application Failure',
  DELETE_APPLICATION_FILE_FAILURE = '[Application Files API] Delete Application File Failure',
  SAVE_APPLICATION_SECTION_FAILURE = '[Application API] Save Application Section Failure',
}

export enum LoadingActionTypes {
  START_LOADING = '[Invitation Loading] Start loading',
  FINISH_LOADING = '[Invitation Loading] Finish loading',
  SET_ERROR = '[Invitation Error] Set error',
}
