import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as RandExp from 'randexp';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { InputErrorStateMatcher } from 'src/app/shared/validators/error-state-matcher';
import { POSTCODE_REGEXES } from 'src/app/shared/components/postal-code/postcode-regexes';
import { CountryCode } from 'src/app/shared/components/postal-code/postcode-types';
@Component({
  selector: 'osre-postal-code',
  templateUrl: './postal-code.component.html',
  styleUrls: ['./postal-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostalCodeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PostalCodeComponent)
    },
    {
      provide: MatFormFieldControl,
      multi: true,
      useExisting: forwardRef(() => PostalCodeComponent)
    }
  ]
})
export class PostalCodeComponent implements OnInit, OnChanges, ControlValueAccessor, MatFormFieldControl<string> {
  @Input() countryCode: string;
  defaultCountryCode = CountryCode.NL;

  public ngControl: NgControl;
  errorMatcherInput = new InputErrorStateMatcher();

  constructor() {
  }

  get errors(): ValidationErrors | null {
    return this.control.errors;
  }

  get invalid(): boolean {
    return this.control.invalid;
  }

  get dirty(): boolean {
    return this.control.dirty;
  }

  get touched(): boolean {
    return this.control.touched;
  }

  get disabled(): boolean {
    return this.control.disabled;
  }

  get empty(): boolean {
    return this.control.value === '';
  }

  get errorState(): boolean {
    return this.control.invalid;
  }

  get focused(): boolean {
    return false;
  }

  get id(): string {
    return '';
  }

  get stateChanges(): Observable<void> {
    return this.control.valueChanges;
  }

  get value(): string {
    return this.control.value;
  }

  get placeholderExample() {
    const randExp = new RandExp(this.countryValidationPattern);
    return randExp.gen();
  }

  get countryValidationPattern() {
    return POSTCODE_REGEXES.get(this.countryCode || this.defaultCountryCode);
  }

  @Input() required = false;

  @Input() htmlClass: string = null;
  @Input() placeholder = '';
  private onChanged;
  private onTouched;

  @ViewChild('inputElement', {static: true}) inputElement;

  public nativeElement;

  public control: FormControl = this.buildControl();

  public postalCodeForm: FormGroup = new FormGroup({control: this.control});

  readonly shouldLabelFloat: true;

  private buildControl(): FormControl {
    const validators = [Validators.pattern('^[1-9]{1}[0-9]{3}\\s{1}[A-Z]{2}$'), Validators.required];

    const control = new FormControl('', validators);

    control.valueChanges.pipe(
      map(value => this.formatPostalCode(value)),
      filter(() => this.onChanged && this.onTouched)
    ).subscribe(value => {
      if (value !== this.control.value) {
        control.setValue(value);
      }

      this.onChanged(value);
      this.onTouched();
    });

    return control;
  }

  ngOnInit() {
    this.control = this.buildControl();
    this.postalCodeForm = new FormGroup({control: this.control });
    this.nativeElement = this.inputElement.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countryCode?.currentValue !== changes.countryCode?.previousValue) {;
      const validators = [Validators.pattern(this.countryValidationPattern), Validators.required];
      this.control.setValidators(validators);
      this.control.updateValueAndValidity();
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(postalCode: string | null): void {
    if (!postalCode) {
      this.control.setValue(postalCode, {emitEvent: false});
      return;
    }

    this.control.setValue(this.formatPostalCode(postalCode));
  }

  formatPostalCode(postalCode: string) {
    if (this.countryCode === CountryCode.NL && postalCode && postalCode.length > 4) {
      const code = postalCode.replace(/ /g, '');
      return code.substr(0, 4) + ' ' + code.substr(4, 2).toUpperCase();
    }

    return postalCode ? postalCode.toUpperCase() : '';
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.errors;
  }

  onContainerClick(event: MouseEvent): void {

  }

  setDescribedByIds(ids: string[]): void {
  }
}
