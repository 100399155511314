import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  ExpiredInvitationPageComponent,
  NotFoundPageComponent,
  PublicLayoutComponent,
  SecureLayoutComponent
} from './shared/components';
import { PreApplicationGuard } from 'src/app/shared/route-guards/pre-application.guard';
import { AuthGuard } from 'src/app/shared/route-guards/auth.guard';
import { SelfServiceGuard } from 'src/app/shared/route-guards/self-service.guard';
import { AppointmentConfirmPageComponent } from 'src/app/shared/components/appointment-confirm-page/appointment-confirm-page.component';
import { AppointmentsResolver } from 'src/app/shared/services/appointments.resolver';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () => import('./on-osre-landing/on-osre-landing.module').then(m => m.OnOsreLandingModule),
  },
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    component: SecureLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./full-application/full-application.module').then(m => m.FullApplicationModule),
  },
  {
    path: 'invitation/:id',
    component: PublicLayoutComponent,
    canActivate: [PreApplicationGuard],
    loadChildren: () => import('./invitation/invitation.module').then(m => m.InvitationModule),
  },
  {
    path: 'invitation/:id/token/:magicToken',
    component: PublicLayoutComponent,
    canActivate: [PreApplicationGuard],
    loadChildren: () => import('./invitation/invitation.module').then(m => m.InvitationModule),
  },
  {
    path: 'self-service/:dossierId/:magicToken',
    component: PublicLayoutComponent,
    canActivate: [SelfServiceGuard],
    loadChildren: () => import('./self-service/self-service.module').then(m => m.SelfServiceModule),
  },
  { path: '404', component: NotFoundPageComponent },
  { path: 'expired', component: ExpiredInvitationPageComponent },
  {
    path: 'appointment/:appointmentId/:action/:magicToken',
    component: AppointmentConfirmPageComponent,
    resolve: {
      appointmentData: AppointmentsResolver
    },
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
