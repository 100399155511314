import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'osre-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() config: {
    title?: string;
    titleClass?: string;
    description?: string;
    descriptionClass?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonClass?: string;
    cancelButtonClass?: string;
    confirmButtonIcon?: string;
    cancelButtonIcon?: string;
    actionsPosition?: string;
    actionsClass?: string;
  } = {
    title: this.translateService.instant('Confirm'),
    confirmButtonText: this.translateService.instant('Yes'),
    cancelButtonText: this.translateService.instant('No'),
    confirmButtonClass: 'btn-success',
    cancelButtonClass: 'btn-info me-3',
    actionsPosition: 'end',
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data, private translateService: TranslateService) {
    this.config = {...this.config, ...data.config};
  }

  ngOnInit() {
  }

}
