import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'osre-public-layout-component',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
