import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { keyBy, get } from 'lodash';

import { OrganizationDetailsState } from 'src/app/store/organization-store/org-details-state';
import { IAttachmentRules, IOrganizationDetails } from 'src/app/shared/models/organization-details';
import { IDomain } from 'src/app/shared/models/portal-domain';
import { IAttachmentRule } from 'src/app/shared/models/custom-application';

const getIsLoading = (state: OrganizationDetailsState): boolean => state.isLoading;

const getError = (state: OrganizationDetailsState): any => state.error;

const getOrgDetails = (state: OrganizationDetailsState): any => state.details ? state.details.data.attributes.settings : null;

const getOrgDomains = (state: OrganizationDetailsState): IDomain => state.domains;

const getOrgDomainsLoading = (state: OrganizationDetailsState): boolean => state.domainsLoading;

export const selectOrgDetailsState: MemoizedSelector<object, OrganizationDetailsState> =
  createFeatureSelector<OrganizationDetailsState>('organization-details');

export const selectOrgDetailsError: MemoizedSelector<object, any> = createSelector(
  selectOrgDetailsState,
  getError,
);

export const selectOrgDetailsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectOrgDetailsState,
  getIsLoading,
);

export const selectOrganizationDetails: MemoizedSelector<object, IOrganizationDetails> = createSelector(
  selectOrgDetailsState,
  getOrgDetails,
);

export const selectOrganizationAccountId: MemoizedSelector<OrganizationDetailsState, string> = createSelector(
  selectOrgDetailsState,
  state => state.details.data.attributes.accountId,
);

export const selectOrganizationDomains: MemoizedSelector<object, any> = createSelector(
  selectOrgDetailsState,
  getOrgDomains,
);

export const selectOrganizationDomainsLoading: MemoizedSelector<object, any> = createSelector(
  selectOrgDetailsState,
  getOrgDomainsLoading,
);

export const selectAttachmentRules: MemoizedSelector<object, IAttachmentRules> = createSelector(
  selectOrgDetailsState,
  state => get(state.details, 'data.attributes.applicationFormSettings.attachmentsRules')
    ? {
      manual: get(state.details, 'data.attributes.applicationFormSettings.attachmentsRules.manual')
        ? keyBy(state.details.data.attributes.applicationFormSettings.attachmentsRules.manual, 'attachment')
        : null,
      ockto: get(state.details, 'data.attributes.applicationFormSettings.attachmentsRules.ockto')
        ? keyBy(state.details.data.attributes.applicationFormSettings.attachmentsRules.ockto, 'attachment')
        : null,
    }
    : null,
);
