import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './documentless-reducer';
import { DocumentlessStoreEffects } from './documentless-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('documentless', reducer),
    EffectsModule.forFeature([DocumentlessStoreEffects])
  ]
})
export class DocumentlessStoreModule { }
