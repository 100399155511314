import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { map } from 'rxjs/operators';
import { uniqBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private apiService: ApiService) { }

  getPortalDomains() {
    return this.apiService.getSingle('authentication', '/accounts/portal-domains').pipe(
      map(res => {
        return {
          ...res,
          data: uniqBy(res.data, 'attributes.accountTitle')
        };
      })
    );
  }
  getAllPortalDomains() {
    return this.apiService.getSingle('authentication', '/accounts/portal-domains');
  }
}
