import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { State } from './state';

const getQueryParams = (state: State): any => state.state.queryParams;
const getRouteParams = (state: State): any => state.state.params;
const getUrl = (state: State): any => state.state.url;

export const selectRouter: MemoizedSelector<
  object,
  State
  > = createFeatureSelector<State>('router');

export const selectQueryParams: MemoizedSelector<object, any> = createSelector(
  selectRouter,
  getQueryParams
);

export const selectRouteParams: MemoizedSelector<object, any> = createSelector(
  selectRouter,
  getRouteParams
);

export const selectRouteUrl: MemoizedSelector<object, string> = createSelector(
  selectRouter,
  getUrl
);
