import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'osre-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.scss']
})
export class SingleFileUploadComponent implements OnInit {

  file: File | null = null;
  @Output() onFileUpload = new EventEmitter<any>();
  @Input() cssMargin: string;

  placeholder = marker('Select file');

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    this.file = event && event.item(0);
    this.onFileUpload.emit(this.file);
  }
  constructor() { }

  ngOnInit() {
    this.cssMargin = this.cssMargin || 'mb-0';
  }
}
