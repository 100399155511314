import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DocumentlessService } from 'src/app/shared/services/documentless.service';
import * as DocumentlessActions from 'src/app/store/documentless-store/actions';
import { switchMap, map, exhaustMap, catchError, concatMap } from 'rxjs/operators';
import { from, of } from 'rxjs';


@Injectable()
export class DocumentlessStoreEffects {
  constructor(private actions$: Actions, private documentlessService: DocumentlessService) {
  }

  getQrCode$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getQrCodeRequestAction),
    concatMap(action => {
      return this.documentlessService.getQRCode(action.id, action.withPartner, action.forPartner, action.candidateData, action.applicationType, action.magicToken, action.partnerData).pipe(
        exhaustMap(qrCodeResult => from(this.documentlessService.convertToBase64FromBlob(qrCodeResult))),
        map(response => DocumentlessActions.getQrCodeSuccessAction({ payload: response, forPartner: action.forPartner })),
        catchError(err => of(DocumentlessActions.getQrCodeFailure({ payload: { error: err } }))),
      );
    }),
  ));

  getApplicationLink$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getApplicationLinkRequestAction),
    concatMap(action => {
      return this.documentlessService.getApplicationLink(action.id, action.withPartner, action.forPartner, action.candidateData, action.applicationType, action.magicToken).pipe(
        map(response => DocumentlessActions.getApplicationLinkSuccessAction({
          payload: response.data.attributes.applicationLink,
          forPartner: action.forPartner,
        })),
        catchError(err => of(DocumentlessActions.getApplicationLinkFailureAction({ payload: { error: err } }))),
      );
    }),
  ));

  getAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getAvailabilityRequestAction),
    switchMap(action => {
      return this.documentlessService.getOcktoAvailability(action.magicToken).pipe(
        map(response => DocumentlessActions.getAvailabilitySuccessAction({ payload: response.isAvailable })),
        catchError(err => of(DocumentlessActions.getAvailabilityFailureAction({ payload: { error: err } }))),
      );
    }),
  ));

  getFinancialSession$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getFinancialSessionRequestAction),
    switchMap(action => {
        return this.documentlessService.getSessionStatus(action.payload.id, action.payload.type, action.payload.magicToken).pipe(
          map(response => DocumentlessActions.getFinancialSessionSuccessAction({ payload: response })),
          catchError(err => of(DocumentlessActions.getFinancialSessionErrorAction({ error: err }))),
        );
      },
    ),
  ));

  getActiveProvider$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getActiveProvider),
    switchMap(action => this.documentlessService.getActiveProviders(action.accountId).pipe(
      map(response => DocumentlessActions.getActiveProviderSuccess({ activeProvider: response })),
      catchError(error => of(DocumentlessActions.getActiveProviderFailure({ error }))),
    )),
  ));

  getActiveProviderByToken$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentlessActions.getActiveProviderByToken),
    switchMap(action => this.documentlessService.getActiveProvidersByToken( action.magicToken).pipe(
      map(response => DocumentlessActions.getActiveProviderSuccess({ activeProvider: response })),
      catchError(error => of(DocumentlessActions.getActiveProviderFailure({ error }))),
    )),
  ));
}
