import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { selectAuthLoading, selectLoggedInUser } from 'src/app/store/auth-store/auth-selectors';
import { Store } from '@ngrx/store';
import * as authState from 'src/app/store/auth-store/auth.state';
import { takeUntil } from 'rxjs/operators';
import { getUserDetailsRequestAction, logoutRequestAction } from 'src/app/store/auth-store/actions';
import * as orgDetailsState from 'src/app/store/organization-store/org-details-state';
import { selectOrganizationDetails } from 'src/app/store/organization-store/org-details-selectors';
import { IOrganizationDetails } from 'src/app/shared/models/organization-details';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'osre-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private getOrgDetails$ = this.orgDetailsStore.select(selectOrganizationDetails);

  loggedInUser$ = this.authStore.select(selectLoggedInUser);
  isLoading$ = this.authStore.select(selectAuthLoading);
  user: { name: string; email: string };
  logo: string | null;

  constructor(
    private authStore: Store<authState.AuthState>,
    private orgDetailsStore: Store<orgDetailsState.OrganizationDetailsState>,
    public translateService: TranslateService,
  ) { }

  ngOnInit() {

    this.loggedInUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (!!response) {
          this.user = this.getUserDetails(response);
          return;
        } else {
          this.authStore.dispatch(getUserDetailsRequestAction());
        }
      });

    this.getOrgDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((details: IOrganizationDetails) => {
        if (!details) {
          return;
        }
        this.logo = details.relet.portalLogoUrl ? details.relet.portalLogoUrl : '';
      });
  }

  getUserDetails(response: any) {
    return { name: response.attributes.name, email: response.attributes.email };
  }

  logout() {
    this.authStore.dispatch(logoutRequestAction());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeLang(language: string) {
    this.translateService.use(language);
  }

}
