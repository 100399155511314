<h1 mat-dialog-title [ngClass]="config.titleClass">{{config.title}}</h1>
<div mat-dialog-content [ngClass]="config.descriptionClass">{{config.description}}</div>
<div mat-dialog-actions class="d-flex justify-content-{{config.actionsPosition}} {{config.actionsClass}}">
  <button class="btn {{config.cancelButtonClass}}" mat-dialog-close>
    <mat-icon *ngIf="config.cancelButtonIcon" class="align-middle me-2">{{config.cancelButtonIcon}}</mat-icon>
    <span>{{config.cancelButtonText}}</span>
  </button>
  <button class="btn {{config.confirmButtonClass}}" [mat-dialog-close]="true">
    <mat-icon *ngIf="config.confirmButtonIcon" class="align-middle me-2">{{config.confirmButtonIcon}}</mat-icon>
    <span>{{config.confirmButtonText}}</span>
  </button>
</div>
