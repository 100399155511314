import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { RequestActionTypes, SuccessActionTypes } from 'src/app/store/invitation-store/invitation-types';
import * as invitationActions from './actions';
import * as state from './invitation.state';
import * as orgDetailsActions from 'src/app/store/organization-store/actions';
import { InvitationService } from '../../shared/services/invitation.service';
import { ApplicationService } from 'src/app/full-application/application.service';
import { Router } from '@angular/router';
import { redirectTo } from './actions';

@Injectable()
export class InvitationStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<state.InvitationState>,
    private router: Router,
    private invitationService: InvitationService,
    private applicationService: ApplicationService,
  ) {
  }

  startLoading$ = createEffect(() => this.actions$.pipe(
    ofType(...Object.values(RequestActionTypes)),
    map(() => invitationActions.startInvitationLoadingAction()),
  ));

  finishLoading$ = createEffect(() => this.actions$.pipe(
    ofType(...Object.values(SuccessActionTypes)),
    map(() => invitationActions.finishInvitationLoadingAction()),
  ));

  // getInvitation$ = createEffect(() => this.actions$.pipe(
  //   ofType(invitationActions.getInvitationRequestAction),
  //   switchMap(action => {
  //     return this.invitationService.getInvitation(action.id).pipe(
  //       switchMap(invitation => {
  //         if (invitation.data && invitation.data.attributes.applicationId) {
  //           return this.applicationService.getApplicationById(invitation.data.attributes.applicationId).pipe(
  //             switchMap(application => [
  //               invitationActions.getInvitationSuccessAction({payload: invitation.data}),
  //               // invitationActions.getApplicationByIdSuccessAction({application: application.data}),
  //             ]),
  //             catchError(err => of(invitationActions.setInvitationErrorAction({payload: {error: err.error.errors}}))),
  //           );
  //         } else {
  //           return [invitationActions.getInvitationSuccessAction({payload: invitation.data})];
  //         }
  //       }),
  //       catchError(err => of(invitationActions.setInvitationErrorAction({payload: {error: err}}))),
  //     );
  //   }),
  // ));
  getInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.getInvitationRequestAction),
    switchMap(action => {
      return this.invitationService.getInvitation(action.id).pipe(
        switchMap(response => [
          invitationActions.getInvitationSuccessAction({ payload: response.data }),
          orgDetailsActions.getAccountSettingsRequest({ accountId: response.data.attributes.accountId }),
        ]),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err } }))),
      );
    }),
  ));

  saveInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.saveInvitationDetailsRequestAction),
    map(action => invitationActions.saveInvitationDetailsSuccessAction({ payload: action.invitation })),
  ));

  saveApplicationDraft$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.savePreApplicationDraftRequestAction),
    switchMap(action => {
      return of(invitationActions.savePreApplicationDraftSuccessAction({ payload: action.application }));
    }),
  ));

  saveProspectApplicationDraft$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.saveProspectApplicationRequestAction),
    switchMap(action => {
      return of(invitationActions.saveProspectApplicationSuccessAction({ payload: action.prospectApplication }));
    }),
  ));

  getApplicationList$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.getApplicationListRequestAction),
    switchMap(() => {
      return this.applicationService.getApplications().pipe(
        map(response => invitationActions.getApplicationListSuccessAction({ payload: response.data })),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err.error.errors } }))),
      );
    }),
  ));

  getApplicationById$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.getApplicationByIdRequestAction),
    switchMap(action => {
      return this.applicationService.getApplicationById(action.id, action.magicToken).pipe(
        map(response => invitationActions.getApplicationByIdSuccessAction({ application: response.data })),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err.error.errors } }))),
      );
    }),
  ));

  finalizeApplication$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.finalizeFullApplicationRequest),
    switchMap(action => {
      return this.applicationService.finalizeApplication(action.id, action.application).pipe(
        map(response => {
          if (response) {
            this.router.navigate(['/dashboard']).then(() => false);
          }
          return invitationActions.finalizeFullApplicationSuccess();
        }),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err.error.errors } }))),
      );
    }),
  ));

  saveApplicationFiles$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.saveFilesRequest),
    switchMap(action => {
      return forkJoin(action.requests).pipe(
        map(response => {
          if (response) {
            // this.router.navigate(['/application', action.applicationId]).then(() => false);
          }
          return invitationActions.saveFilesSuccess({ filesUploaded: true });
        }),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err.error.errors } }))),
      );
    }),
  ));

  saveApplicationSectionDetails$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.saveApplicationSectionRequest),
    switchMap(action => {
      return this.invitationService.updateApplication(action.data, action.applicationId).pipe(
        switchMap((response) => {
          return action.redirect ? [
            invitationActions.saveApplicationSectionSuccess({
              data: response.data,
              redirect: !!action.redirect,
              updateState: action.updateState,
            }),
            redirectTo({ url: action.redirect }),
          ] : [invitationActions.saveApplicationSectionSuccess({ data: response.data, redirect: !!action.redirect })];
        }),
        catchError(err => of(invitationActions.setInvitationErrorAction({ payload: { error: err.error.errors } }))),
      );
    }),
  ));

  deleteApplicationFile$ = createEffect(() => this.actions$.pipe(
    ofType(invitationActions.deleteFileRequest),
    switchMap(action => this.invitationService.deleteApplicationFile(action.applicationId, action.attachmentId).pipe(
      map(() => invitationActions.deleteFileSuccess({ attachmentId: action.attachmentId })),
      catchError(err => of(invitationActions.deleteFileFailure({ error: err }))),
      ),
    )),
  );

  redirectTo$ = createEffect(() => this.actions$.pipe(
    ofType(redirectTo),
    switchMap(({ url }: any) => this.router.navigate(url)),
  ), { dispatch: false });

}
