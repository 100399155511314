import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes, FailureActionTypes } from 'src/app/store/documentless-store/documentless-types';
import { QrAttributes } from 'src/app/shared/models/financial-session';

export const getQrCodeRequestAction = createAction(
  RequestActionTypes.GET_QR_CODE_REQUEST,
  props<{
    id: string,
    withPartner: boolean,
    forPartner?: boolean,
    candidateData?: QrAttributes,
    partnerData?: QrAttributes,
    applicationType?: string,
    magicToken?: string
  }>(),
);

export const getQrCodeSuccessAction = createAction(
  SuccessActionTypes.GET_QR_CODE_SUCCESS,
  props<{ payload: string, forPartner: boolean }>(),
);

export const getQrCodeFailure = createAction(
  FailureActionTypes.GET_QR_CODE_FAILURE,
  props<{ payload: { error: any } }>(),
);

export const clearQrCodeSuccessAction = createAction(
  SuccessActionTypes.CLEAR_QR_CODE_SUCCESS,
);

