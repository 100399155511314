import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes, FailureActionTypes } from 'src/app/store/documentless-store/documentless-types';
import { ActiveProvider } from 'src/app/shared/models/financial-session';

const moduleName = 'Documentless API';

export const getAvailabilityRequestAction = createAction(
  RequestActionTypes.GET_AVAILABILITY_REQUEST,
  props<{ magicToken: string }>(),
);

export const getAvailabilitySuccessAction = createAction(
  SuccessActionTypes.GET_AVAILABILITY_SUCCESS,
  props<{ payload: boolean }>(),
);

export const getAvailabilityFailureAction = createAction(
  FailureActionTypes.GET_AVAILABILITY_FAILURE,
  props<{ payload: { error: any } }>(),
);

export const getActiveProvider = createAction(
  `[${moduleName}] Get Active Provider`,
  props<{ accountId: string }>(),
);

export const getActiveProviderByToken = createAction(
  `[${moduleName}] Get Active Provider By Token`,
  props<{ magicToken: string }>(),
);

export const getActiveProviderSuccess = createAction(
  `[${moduleName}] Get Active Provider Success`,
  props<{ activeProvider: ActiveProvider }>(),
);

export const getActiveProviderFailure = createAction(
  `[${moduleName}] Get Active Provider Failure`,
  props<{ error: any }>(),
);
