import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as orgDetailsState from 'src/app/store/organization-store/org-details-state';

import {
  selectOrganizationDetails,
  selectOrgDetailsError,
  selectOrgDetailsLoading,
} from 'src/app/store/organization-store/org-details-selectors';
import { IOrganizationDetails } from 'src/app/shared/models/organization-details';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'osre-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() accountId: string | null;

  errorMsg: Observable<string | any>;
  title: Observable<string>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  isLoading$ = this.orgDetailsStore.select(selectOrgDetailsLoading);
  details: IOrganizationDetails;

  private getOrgDetails$ = this.orgDetailsStore.select(selectOrganizationDetails);
  private getOrgDetailsError$ = this.orgDetailsStore.select(selectOrgDetailsError);

  constructor(
    private orgDetailsStore: Store<orgDetailsState.OrganizationDetailsState>,
    public translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.title = this.translateService.stream('Do you have questions? Please contact the rental agent');

    this.getOrgDetails$.subscribe(details => {
      if (!details) {
        return;
      }
      this.details = details;
    });

    this.getOrgDetailsError$.subscribe(error => {
      if (!error) {
        return;
      }
      this.errorMsg = this.translateService.stream('Could not load contact details..');
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
