<div class="container mt-3 text-center">
  <osre-language-switcher class=""></osre-language-switcher>

  <div *ngIf="isAppointmentInvitationAccepted && !errors">
    <h3 class="mt-5" translate>Thank you for accepting the appointment invitation. You can download the meeting details from one of the buttons below</h3>
    <div class="mt-3 appointment-buttons d-flex justify-content-end align-items-center">
      <button class="bg-success text-white py-2 d-flex align-items-center" mat-flat-button (click)=openGoogleCalendar(appointment)>
        <span translate>Go to google calendar</span>
        <mat-icon class="ms-2">open_in_new</mat-icon>
      </button>
      <button class="bg-info text-white py-2 d-flex align-items-center ms-2" mat-flat-button (click)="downloadICalFile(appointmentId)">
        <span translate>Download iCal</span>
        <mat-icon class="ms-2">download</mat-icon>
      </button>
    </div>
  </div>
  <h3 *ngIf="!isAppointmentInvitationAccepted && !errors" class="mt-5" translate>You have declined the appointment invitation</h3>
  <h3 *ngIf="errors" translate>You can no longer answer to this invitation</h3>
</div>
