import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

type ServiceType = keyof typeof environment.msApi;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  /**
   * Map and normalize the JSON response
   * @param  data - response data
   * @param endpoint - endpoint url
   */
  private static responseMapJson(data: any, endpoint: string) {
    if (data) {
      return Object.assign({}, data);
    } else {
      return;
    }
  }

  /**
   * Get service URL for service type from env config
   * @param serviceType service type key
   */
  private static getServiceUrl(serviceType: string) {
    return environment.msApi[serviceType];
  }

  private static toQueryString(params: any) {
    let encodedStr = '';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (encodedStr && encodedStr[encodedStr.length - 1] !== '&') {
          encodedStr = encodedStr + '&';
        }
        const value: any = params[key];
        if (value instanceof Array) {
          for (const i in value) {
            if (value.hasOwnProperty(i)) {
              encodedStr = encodedStr + key + '=' + encodeURIComponent(value[i]) + '&';
            }
          }
        } else if (typeof value === 'object') {
          for (const innerKey in value) {
            if (value.hasOwnProperty(innerKey)) {
              encodedStr = encodedStr +
                key + '[' + innerKey + ']=' + encodeURIComponent(value[innerKey]) + '&';
            }
          }
        } else {
          encodedStr = encodedStr + key + '=' + encodeURIComponent(value);
        }
      }
    }
    if (encodedStr[encodedStr.length - 1] === '&') {
      encodedStr = encodedStr.substr(0, encodedStr.length - 1);
    }
    return encodedStr;
  }

  public getSingle(serviceType: ServiceType, url: string, params?: any): Observable<any> {

    return this.makeRequest(serviceType, 'GET', url, params);
  }

  public getAll(serviceType: ServiceType, url: string, params?: any): Observable<any> {
    return this.makeRequest(serviceType, 'GET', url, params);
  }

  public getText(serviceType: ServiceType, url: string, params?: any): Observable<any> {
    return this.makeRequest(serviceType, 'GET', url, params, 'text');
  }

  public getBlob(serviceType: ServiceType, url: string, params?: any): Observable<any> {
    return this.makeRequest(serviceType, 'GET', url, params, 'blob');
  }

  public saveBlob(serviceType: ServiceType, url: string, params?: any): Observable<any> {
    return this.makeRequest(serviceType, 'POST', url, params, 'blob');
  }

  public save(serviceType: ServiceType, url: string, params?: any): Observable<any> {
    return this.makeRequest(serviceType, 'POST', url, params);
  }

  public update(serviceType: ServiceType, url: string, params?: any): Observable<any> {

    return this.makeRequest(serviceType, 'PUT', url, params);
  }

  public delete(serviceType: ServiceType, url: string): Observable<any> {

    return this.makeRequest(serviceType, 'DELETE', url);
  }

  /**
   * Make the actual request
   */
  private makeRequest(serviceType: string, method: string, url: string, params?: any,
                      responseType: 'json'|'blob'|'text' = 'json'): Observable<any> {

    // Get base service url from environment config and merge with request url
    let requestUrl = ApiService.getServiceUrl(serviceType) + url;
    let request;

    if (method === 'GET') {
      if (params) {
        requestUrl += `?${ApiService.toQueryString(params)}`;
      }
      request = this.http.request(method, requestUrl, {responseType});
    } else {
      request = this.http.request(method, requestUrl, { body: params, responseType });
    }

    if (responseType === 'json') {
      return request.pipe(map(result => ApiService.responseMapJson(result, url)));
    } else {
      return request;
    }
  }
}
