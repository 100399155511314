import { IInvitation } from 'src/app/shared/models/invitation';
import { IApplication, IApplicationDetails, IProspectApplication } from 'src/app/shared/models/application';
import { IApplicationList } from 'src/app/shared/models/application-list';

export interface InvitationState {
  application: IApplication;
  selectedApplication: IApplicationDetails;
  invitation: IInvitation;
  applicationList: IApplicationList[];
  error: any | null;
  isLoading: boolean;
  success: boolean;
  filesUploaded: boolean;
  prospectApplication: IProspectApplication;
}

export const initialState: InvitationState = {
  application: null,
  selectedApplication: null,
  invitation: null,
  applicationList: null,
  error: null,
  isLoading: false,
  success: false,
  filesUploaded: false,
  prospectApplication: null
};
