import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { RequestActionTypes, SuccessActionTypes } from 'src/app/store/organization-store/org-details-types';
import * as orgDetailsActions from './actions';
import * as state from './org-details-state';

import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';

@Injectable()
export class OrganizationStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<state.OrganizationDetailsState>,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private orgService: OrganizationService
  ) {
  }

  startLoading$ = createEffect(() => this.actions$.pipe(
    ofType(...Object.values(RequestActionTypes)),
    map(() => orgDetailsActions.startAccountSettingsLoadingAction()),
  ));

  finishLoading$ = createEffect(() => this.actions$.pipe(
    ofType(...Object.values(SuccessActionTypes)),
    map(() => orgDetailsActions.finishAccountSettingsLoadingAction()),
  ));

  getAccountSettings$ = createEffect(() => this.actions$.pipe(
    ofType(orgDetailsActions.getOrgDetailsRequest),
    switchMap(() => {
      return this.accountService.getAccountDomain(this.accountService.getCurrentAccountDomain()).pipe(
        map((payload) => {
          return orgDetailsActions.getOrgDetailsSuccess({ payload });
        }),
        catchError(err => of(orgDetailsActions.getOrgDetailsFailure({error: err})))
      );
    }),
    catchError(error => of(orgDetailsActions.getOrgDetailsFailure({ error }))),
  ));

  getAccountSettingsForRoot$ = createEffect(() => this.actions$.pipe(
    ofType(orgDetailsActions.getOrgDetailsForRootRequest),
    switchMap(() => {
      return this.accountService.getAccountDomain(this.accountService.getCurrentAccountDomain()).pipe(
        map((payload) => {
          return orgDetailsActions.getOrgDetailsForRootSuccess({ payload });
        }),
        catchError(err => of(orgDetailsActions.getOrgDetailsForRootFailure({error: err})))
      );
    }),
  ));
  getPortalDomains$ = createEffect(() => this.actions$.pipe(
    ofType(orgDetailsActions.getPortalDomainsRequest),
    switchMap(() => {
      return this.orgService.getPortalDomains().pipe(
        map(payload => {
          return orgDetailsActions.getPortalDomainsSuccess({payload});
        }),
        catchError(err => of(orgDetailsActions.getPortalDomainsFailure({error: err})))
      );
    })
  ));

  getAllPortalDomains$ = createEffect(() => this.actions$.pipe(
    ofType(orgDetailsActions.getAllPortalDomainsRequest),
    switchMap(() => this.orgService.getAllPortalDomains().pipe(
      map(payload => orgDetailsActions.getPortalDomainsSuccess({payload})),
      catchError(err => of(orgDetailsActions.getPortalDomainsFailure({error: err}))),
    )),
  ));

  getAccountSettingsNoDomain$ = createEffect(() => this.actions$.pipe(
    ofType(orgDetailsActions.getAccountSettingsRequest),
    switchMap(({accountId}) => this.authService.getAccountSettings(accountId).pipe(
      map(payload => orgDetailsActions.getAccountSettingsSuccess({ payload }))),
    ),
  ));
}
