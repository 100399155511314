import { createAction, props } from '@ngrx/store';
import { LoadingActionTypes } from 'src/app/store/organization-store/org-details-types';

export const startAccountSettingsLoadingAction = createAction(
  LoadingActionTypes.START_LOADING
);

export const finishAccountSettingsLoadingAction = createAction(
  LoadingActionTypes.FINISH_LOADING
);

export const setAccountSettingsErrorAction = createAction(
  LoadingActionTypes.SET_ERROR,
  props<{ payload: { error: any } }>()
);
