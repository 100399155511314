import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isValidUser();
  }

  isValidUser() {
    return this.authService.isAuthenticated().pipe(
      map((token) => {
        if (token) {
          return true;
        }

        this.authService.logout().subscribe(() => {
          this.router.navigate(['login']).then(() => {
            return false;
          });
        });
      })
    );
  }
}
