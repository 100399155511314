import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {

  constructor(private apiService: ApiService) {
  }

  getAppointmentRespondToInvite(appointmentId: string, action: 'accept' | 'decline', magicToken: string): Observable<any> {
    return this.apiService.getSingle('appointment', `/appointment/${appointmentId}/${action}/${magicToken}`);
  }

  getAppointmentICalFile(appointmentId: string): Observable<any> {
    return this.apiService.getBlob('appointment', `/appointment/${appointmentId}/ical`);
  }
}
