import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Observable } from 'rxjs';
import { IOrganizationDetailsRequest } from 'src/app/shared/models/organization-details';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private api: ApiService) { }

  /**
   * Get account settings by domain.
   */
  public getAccountDomain(domain: string): Observable<IOrganizationDetailsRequest> {
    return this.api.getAll('authentication', `/accounts/domains/${domain}/settings`);
  }

  public getCurrentAccountDomain(): string {
    return window.location.hostname;
  }

  public getCurrentBaseUrl(): string {
    return window.location.protocol + '//' + window.location.hostname;
  }
}
