import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectOrganizationDetails, selectOrgDetailsError } from 'src/app/store/organization-store/org-details-selectors';
import * as orgDetailsState from 'src/app/store/organization-store/org-details-state';
import { UpdateService } from 'src/app/shared/services/update.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'osre-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Relet Portal';
  private defaultFavicon = './assets/favicon.ico';

  public accountDomain = this.orgDetailsStore.select(selectOrganizationDetails);

  constructor(
    private orgDetailsStore: Store<orgDetailsState.OrganizationDetailsState>,
    private updateService: UpdateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.accountDomain.subscribe(accountDomain => {
      if (!accountDomain || accountDomain.relet.portalFaviconUrl === '') {
        return;
      }

      const icon = accountDomain.relet.portalFaviconUrl === '' ? this.defaultFavicon : accountDomain.relet.portalFaviconUrl;
      this.document.getElementById('appFavicon').setAttribute('href', icon);
    });
  }
}
