import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';

import { AuthState } from './auth.state';
import { IUser } from 'src/app/shared/models/user';

const getError = (state: AuthState): any => state.error;

const getIsLoading = (state: AuthState): boolean => state.isLoading;

const getLoggedInUser = (state: AuthState): any => state.user;

const getAuthenticationState = (state: AuthState): boolean => state.isAuthenticated;

const getAccountActivatedState = (state: AuthState): boolean => state.activated;

const getAccountRegisteredState = (state: AuthState): boolean => state.registered;

const getSuccessState = (state: AuthState): boolean => state.success;

export const selectAuthState: MemoizedSelector<object, AuthState> =
  createFeatureSelector<AuthState>('authentication');

export const selectAuthError: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getError
);

export const selectAuthLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getIsLoading
);

export const selectLoggedInUser: MemoizedSelector<object, IUser> = createSelector(
  selectAuthState,
  getLoggedInUser
);

export const selectAuthenticationState: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getAuthenticationState
);

export const selectAuthActivatedState: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getAccountActivatedState
);

export const selectAuthRegisteredState: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getAccountRegisteredState
);

export const selectSuccessState: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getSuccessState
);

