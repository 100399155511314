import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as DateFormats from './constants/date-formats';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDividerModule } from '@angular/material/divider';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import {
  PublicLayoutComponent,
  SecureLayoutComponent,
  HeaderComponent,
  FooterComponent,
  ConfirmationMessageComponent,
  NotFoundPageComponent,
  ExpiredInvitationPageComponent,
  NavigationComponent,
  SingleFileUploadComponent,
  LanguageSwitcherComponent,
  PostalCodeComponent,
  ConfirmationModalComponent
} from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusDirective } from 'src/app/shared/directives/auto-focus.directive';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AppointmentConfirmPageComponent } from './components/appointment-confirm-page/appointment-confirm-page.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';


@NgModule({
  declarations: [
    // Components
    PublicLayoutComponent,
    SecureLayoutComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmationMessageComponent,
    NotFoundPageComponent,
    ExpiredInvitationPageComponent,
    NavigationComponent,
    SingleFileUploadComponent,
    LanguageSwitcherComponent,
    PostalCodeComponent,
    // Modals
    ConfirmationModalComponent,
    AutoFocusDirective,
    AppointmentConfirmPageComponent,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    // Vendor Modules
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatDividerModule,
    MatSnackBarModule,
    NgxMatIntlTelInputModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    // Vendor Modules
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatDividerModule,
    MatSnackBarModule,
    // Shared Components
    PublicLayoutComponent,
    SecureLayoutComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmationMessageComponent,
    NavigationComponent,
    PostalCodeComponent,
    SingleFileUploadComponent,
    LanguageSwitcherComponent,
    // Modals
    ConfirmationModalComponent,
    AutoFocusDirective,
    NgxMatIntlTelInputModule,
    CapitalizePipe
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats.DEFAULT_DATE_FORMAT },
  ],
  entryComponents: [
    ConfirmationModalComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
