export const environment = {
  production: false,
  appUrl: 'https://relet-portal.acc.osre.nl',
  msApi: {
    appointment: 'https://appointment.portal.acc.osre.eu',
    authentication: 'https://authentication.portal.acc.osre.eu',
    entree: 'https://entree.portal.acc.osre.eu',
    relet: 'https://relet.portal.acc.osre.eu',
    'financial-check': 'https://financial-check.portal.acc.osre.eu',
    file: 'https://file.portal.acc.osre.eu'
  },
  onOsre: 'https://onosre.com/landlords',
};
