import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'src/app/shared/services/api.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { AccountService } from 'src/app/shared/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // TODO: Create interfaces for <any>
  // Todo: environment based storage prefix (to prevent auth issues during testing on different environments)
  storagePrefix = 'osre.rp.';
  jwtHelper: JwtHelperService;

  constructor(
    private api: ApiService,
    private accountService: AccountService
  ) { }

  /**
   * Login
   * @param accountId - account id
   * @param email - user email
   * @param password - user pass
   */
  login(accountId: string, email: string, password: string): Observable<any> {

    return this.checkLogin(accountId, email, password).pipe(
      concatMap(() => {
        return this.getUserDetails();
      })
    );
  }

  /**
   * Logout
   */
  logout() {
    return this.isAuthenticated().pipe(
      concatMap((authenticated) => {
        if (authenticated) {
          return this.api.update('entree', '/logout').pipe(
            concatMap(() => {
              return this.removeToken();
            })
          );
        } else {
          return this.removeToken();
        }
      })
    );
  }

  /**
   * Check if login credentials are valid
   * @param accountId - account id
   * @param email - user email
   * @param password - user pass
   */
  checkLogin(accountId: string, email: string, password: string): Observable<any> {
    const credentials = { accountId, email, password };
    return this.api.save('entree', '/login', credentials).pipe(
      map((res: any) => {
        const token = res.data && res.data.attributes.session;
        if (token) {
          // set the jwt token in local storage
          this.setToken(token);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  /**
   * Set token in localstorage
   * @param token jwt string
   */
  setToken(token: string) {
    return of(localStorage.setItem(`${this.storagePrefix}session_token`, token));
  }

  /**
   * Get Token
   * @return string or boolean
   */
  getToken(): Observable<string | boolean> {
    const token = localStorage.getItem(this.storagePrefix + 'session_token');
    return this.isTokenValid(token)
      .pipe(map((valid) => {
        if (valid) {
          return token;
        } else {
          return false;
        }
      }));
  }

  /**
   * Remove token from localStorage
   */
  removeToken() {
    return of(localStorage.removeItem(this.storagePrefix + 'session_token'));
  }

  /**
   * Check if token is still valid
   * @param token string
   */
  isTokenValid(token) {
    if (!token) {
      return of(false);
    }

    this.jwtHelper = new JwtHelperService();
    if (this.jwtHelper.isTokenExpired(token)) {
      return of(false);
    }

    return of(true);
  }

  /**
   * Check if user has valid token aka is authenticated
   */
  public isAuthenticated() {
    return this.getToken();
  }


  /**
   * USER SERVICE METHODS
   */

  /**
   * Fetch user info
   */
  getUserDetails(): Observable<any> {
    return this.isAuthenticated().pipe(

      concatMap((authenticated) => {

        if (!authenticated) {
          return throwError('Unauthorized');
        }

        return forkJoin(
          this.getCurrentUser()
        );
      })
    );

  }

  /**
   * Get current user
   * @return observable of user details
   */
  getCurrentUser(): Observable<any> {
    return this.api.getSingle('authentication', '/users/me', {include: 'accountId'}).pipe(
      map(user => {
        return user.data;
      })
    );
  }

  /**
   * Create Portal User
   */
  createUser(params) {
    return this.api.save('authentication', '/users/portal-user', params);
  }

  /**
   * Confirm user
   */
  confirmUser(params: { token: string }) {
    return this.api.update('authentication', '/users/activate-portal-user', params);
  }

  getAccountSettings(accountId: string) {
    return this.api.getSingle('authentication', `/accounts/${accountId}/settings`);
  }

  /**
   * Password change request
   * @param email string
   */
  sendPasswordChangeRequest(email: string): Observable<any> {
    const params = [{ email, baseUrl: `${this.accountService.getCurrentBaseUrl()}/password-change?token=%USERTOKEN%` }];
    return this.api.update('authentication', '/users/password-change-request', params);
  }

  /**
   * Change password request
   * @param  formInput string
   */
  changePassword(formInput: { password: string, passwordConfirm: string, token: string }): Observable<any> {
    const params = [{ password: formInput.password, passwordConfirm: formInput.passwordConfirm, token: formInput.token }];
    return this.api.update('authentication', '/users/password-change', params);
  }
}
