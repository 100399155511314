import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class SelfServiceService {
  constructor(private apiService: ApiService) {
  }

  getDossier(id: string) {
    return this.apiService.getSingle('relet', `/dossiers/${id}`);
  }

  geReservation(dossierId, magicToken) {
    return this.apiService.getSingle('relet', `/reservations/${dossierId}/${magicToken}`);
  }

  changeReservationOptionState(
    reservationId: string,
    optionValue: 'accept' | 'decline',
    magicToken: string,
    params: { acceptedBy: string } | null,
  ): Observable<any> {
    return this.apiService.save('relet', `/reservations/${reservationId}/${optionValue}-option/${magicToken}`, params);
  }
}
