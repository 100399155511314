import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes, FailureActionTypes } from 'src/app/store/organization-store/org-details-types';
import { IOrganizationDetailsRequest } from 'src/app/shared/models/organization-details';
import { IDomain } from 'src/app/shared/models/portal-domain';

export const getOrgDetailsRequest = createAction(RequestActionTypes.GET_ACCOUNT_SETTINGS_REQUEST);

export const getOrgDetailsForRootRequest = createAction(RequestActionTypes.GET_ACCOUNT_SETTINGS_FOR_ROOT_REQUEST);

export const getPortalDomainsRequest = createAction(RequestActionTypes.GET_PORTAL_DOMAINS_REQUEST);

export const getAllPortalDomainsRequest = createAction(RequestActionTypes.GET_ALL_PORTAL_DOMAINS_REQUEST);

export const getOrgDetailsSuccess = createAction(
  SuccessActionTypes.GET_ACCOUNT_SETTINGS_SUCCESS,
  props<{ payload: IOrganizationDetailsRequest }>(),
);

export const getOrgDetailsFailure = createAction(
  FailureActionTypes.GET_ACCOUNT_SETTINGS_FAILURE,
  props<{ error: any }>(),
);

export const getOrgDetailsForRootSuccess = createAction(
  SuccessActionTypes.GET_ACCOUNT_SETTINGS_FOR_ROOT_SUCCESS,
  props<{ payload: IOrganizationDetailsRequest }>(),
);

export const getPortalDomainsSuccess = createAction(
  SuccessActionTypes.GET_PORTAL_DOMAINS_SUCCESS,
  props<{payload: IDomain}>(),
);

export const getOrgDetailsForRootFailure = createAction(
  FailureActionTypes.GET_ACCOUNT_SETTINGS_FOR_ROOT_FAILURE,
  props<{error: any}>(),
);

export const getPortalDomainsFailure = createAction(
  FailureActionTypes.GET_PORTAL_DOMAINS_FAILURE,
  props<{error: any}>(),
);

export const getAccountSettingsRequest = createAction(
  '[Account Settings] Get Account Settings Request',
  props<{ accountId: string }>(),
);

export const getAccountSettingsSuccess = createAction(
  '[Account Settings] Get Account Settings Success',
  props<{ payload: any }>(),
);

export const clearAccountAfterLogout = createAction(
  '[Account Settings] Clear Account After Logout',
);
