import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormBuilder, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'osre-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  languages: any[];
  langSelect: FormControl;

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    public dateAdapter: DateAdapter<any>,
  ) { }

  ngOnInit() {
    this.setDateLocale(this.determineLanguage());
    this.langSelect = this.createForm();

    this.languages = [
      {value: 'en-GB', label: marker('English')},
      {value: 'nl-NL', label: marker('Dutch')},
      {value: 'de-DE', label: marker('German')},
    ];

    if (this.getLanguageFromStorage()) {
      this.langSelect.setValue(this.getLanguageFromStorage());
      this.translateService.use(this.getLanguageFromStorage());
    } else {
      const currentLanguage = this.determineLanguage();
      localStorage.setItem('lang', currentLanguage);
      this.langSelect.setValue(currentLanguage);
      this.translateService.use(currentLanguage);
    }
  }

  public determineLanguage(): string {
    const browserLanguage = window.navigator.language;
    const cachedLang = this.getLanguageFromStorage();
    if (cachedLang) {
      return cachedLang;
    }
    if (['nl-nl', 'nl-be', 'nl'].includes(browserLanguage.toLowerCase())) { // lowercase for iOS safari < 10.2
      return 'nl-NL';
    }

    return 'en-GB';
  }

  createForm() {
    return this.fb.control(null);
  }
  changeLang(language: string) {
    localStorage.setItem('lang', language);
    this.translateService.use(language);
    this.setDateLocale(language);
  }

  getLanguageFromStorage() {
    return localStorage.getItem('lang') || null;
  }

  setDateLocale(language) {
    this.dateAdapter.setLocale(language);
  }

}
