import { createAction, props } from '@ngrx/store';
import { FailureActionTypes, RequestActionTypes, SuccessActionTypes } from 'src/app/store/auth-store/auth-types';
import { IUserRegister } from 'src/app/shared/models/user';

export const getUserDetailsRequestAction = createAction(
  RequestActionTypes.GET_USER_DETAILS_REQUEST
);

export const getUserDetailsErrorAction = createAction(
  '[Auth Api] Get User Details Error',
);

export const getUserDetailsSuccessAction = createAction(
  SuccessActionTypes.GET_USER_DETAILS_SUCCESS,
  props<{ payload: { user: any, isAuthenticated: boolean} }>()
);

export const getUserDetailsFailedAction = createAction(
  FailureActionTypes.GET_USER_DETAILS_FAILURE,
  props<{ error: any }>()
);

export const registerUserRequestAction = createAction(
  RequestActionTypes.REGISTER_USER_REQUEST,
  props<{ payload: { user: IUserRegister } }>()
);

export const registerUserSuccessAction = createAction(
  SuccessActionTypes.REGISTER_USER_SUCCESS,
  props<{ payload: { registered: boolean }}>()
);

export const confirmUserRequestAction = createAction(
  RequestActionTypes.CONFIRM_USER_REQUEST,
  props<{ payload: { token: string } }>()
);

export const confirmUserSuccessAction = createAction(
  SuccessActionTypes.CONFIRM_USER_SUCCESS,
  props<{ payload: { activated: boolean }}>()
);
