import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes, FailureActionTypes } from 'src/app/store/documentless-store/documentless-types';
import { IFinancialSession } from 'src/app/shared/models/financial-session';

export const getFinancialSessionRequestAction = createAction(
  RequestActionTypes.GET_FINANCIAL_SESSION_REQUEST,
  props<{ payload: { id: string, type: string, magicToken?: string } }>(),
);

export const getFinancialSessionSuccessAction = createAction(
  SuccessActionTypes.GET_FINANCIAL_SESSION_SUCCESS,
  props<{ payload: IFinancialSession }>(),
);

export const getFinancialSessionErrorAction = createAction(
  FailureActionTypes.GET_FINANCIAL_SESSION_ERROR,
  props<{ error: any }>(),
);

export const setSessionCompletedOneTimeOnly = createAction(
  SuccessActionTypes.SET_FINANCIAL_SESSION_COMPLETED,
);

export const clearState = createAction(
  SuccessActionTypes.CLEAR_DOCUMENTLESS_STATE,
);

export const setFinancialSession = createAction(
  '[Documentless API] Set Financial Session',
  props<{ financialSession: IFinancialSession }>(),
);

