import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { RequestActionTypes, SuccessActionTypes } from 'src/app/store/auth-store/auth-types';
import * as authActions from './actions';
import * as state from './auth.state';

import * as orgDetailsActions from 'src/app/store/organization-store/actions';

import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/shared/services/account.service';
import { selectOrganizationAccountId } from 'src/app/store/organization-store/org-details-selectors';
import * as orgDetailsState from 'src/app/store/organization-store/org-details-state';

@Injectable()
export class AuthStoreEffects {
  public accountId$ = this.orgDetailsStore.select(selectOrganizationAccountId);

  constructor(
    private actions$: Actions,
    private store: Store<state.AuthState>,
    private authService: AuthenticationService,
    private orgDetailsStore: Store<orgDetailsState.OrganizationDetailsState>,
    private accountService: AccountService,
    private router: Router
  ) {}

  startLoading$ = createEffect(() =>  this.actions$.pipe(
    ofType(...Object.values(RequestActionTypes)),
    map(() => authActions.startAuthLoadingAction()),
  ));

  finishLoading$ = createEffect(() => this.actions$.pipe(
    ofType(...Object.values(SuccessActionTypes)),
    map(() => authActions.finishAuthLoadingAction()),
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loginRequestAction),
    switchMap(action => {
      return this.accountId$.pipe(
        switchMap(accountId => this.authService.login(
          accountId,
          action.credentials.email,
          action.credentials.password
        )),
        map(response => authActions.loginSuccessAction( { payload: { isAuthenticated: true, user: response[0] }})),
        catchError( err => of(authActions.setAuthErrorAction( {payload: { error: err.error }})))
      );
    })
  ));

  passwordReset$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.passwordResetRequestAction),
    switchMap(action => {
      return this.authService.sendPasswordChangeRequest(action.email).pipe(
        map(res => {
          const response = res.data[0];
          if (response && response.attributes.success) {
            return authActions.passwordResetSuccessAction({success: true});
          } else {
            return authActions.setAuthErrorAction( { payload: { error: response.attributes.errors }});
            // return new authenticationActions.SetAuthenticationErrorAction({ error: response.attributes.errors[0].title });
          }
        }),
        catchError(err => of(authActions.setAuthErrorAction( { payload: { error: err.error }})))
      );
    })
  ));

  passwordChange$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.passwordChangeRequestAction),
    switchMap(action => {
      return this.authService.changePassword(action.payload).pipe(
        map(res => {
          const response = res.data[0];

          if (response && response.attributes.success) {
            return authActions.passwordChangeSuccessAction({success: true});
          } else {
            return authActions.setAuthErrorAction( { payload: { error: response.attributes.errors[0] }});
          }
        }),
        catchError(err => of(authActions.setAuthErrorAction( { payload: { error: err.error }})))
      );
    })
  ));

  userDetails$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.getUserDetailsRequestAction),
    switchMap(() => {
      return this.authService.getUserDetails().pipe(
        switchMap(response => [
          authActions.getUserDetailsSuccessAction({payload: {user: response[0], isAuthenticated: true }}),
          orgDetailsActions.getAccountSettingsRequest({accountId: response[0].attributes.accountId}),
        ]),
        catchError(err => of(authActions.getUserDetailsErrorAction()))
      );
    }),
    catchError(error => of(authActions.getUserDetailsFailedAction({ error })))
  ));

  registerUser$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.registerUserRequestAction),
    switchMap((action) => {
      return this.authService.createUser(action.payload.user).pipe(
        map(() => authActions.registerUserSuccessAction( { payload: { registered: true } })),
        catchError(err => of(authActions.setAuthErrorAction( { payload: { error: err.error.errors }})))
      );
    })
  ));

  activateUser$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.confirmUserRequestAction),
    switchMap((action) => {
      return this.authService.confirmUser({ token: action.payload.token }).pipe(
        map(() => authActions.confirmUserSuccessAction({ payload: { activated: true }})),
        catchError(err => of(authActions.setAuthErrorAction( { payload: { error: err.error.errors }})))
      );
    })
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logoutRequestAction),
    exhaustMap(() => this.authService.logout().pipe(
      map(() => orgDetailsActions.clearAccountAfterLogout()),
      catchError(err => of(authActions.setAuthErrorAction({payload: {error: err.error}})))
    ).pipe(
      map(() => authActions.logoutSuccessAction({payload: {isAuthenticated: false, user: null}}))
    )))
  );

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logoutSuccessAction),
    tap(() => this.router.navigate(['/login']))
  ), {dispatch: false});
}
