import { createAction, props } from '@ngrx/store';
import { FailureActionTypes, RequestActionTypes, SuccessActionTypes } from 'src/app/store/invitation-store/invitation-types';
import {
  IApplication,
  IApplicationDetails,
  IApplicationFinalize,
  IProspectApplication,
} from 'src/app/shared/models/application';
import { IApplicationList } from 'src/app/shared/models/application-list';
import { Observable } from 'rxjs';

export const savePreApplicationDraftRequestAction = createAction(
  RequestActionTypes.SAVE_PRE_APPLICATION_REQUEST,
  props<{ application: IApplication }>()
);

export const savePreApplicationDraftSuccessAction = createAction(
  SuccessActionTypes.SAVE_PRE_APPLICATION_SUCCESS,
  props<{ payload: any }>()
);

export const saveProspectApplicationRequestAction = createAction(
  RequestActionTypes.SAVE_PROSPECT_APPLICATION_REQUEST,
  props<{ prospectApplication: IProspectApplication }>()
);
export const saveProspectApplicationSuccessAction = createAction(
  SuccessActionTypes.SAVE_PROSPECT_APPLICATION_SUCCESS,
  props<{ payload: any }>()
);

export const getApplicationListRequestAction = createAction(
  RequestActionTypes.GET_APPLICATION_LIST_REQUEST,
);

export const getApplicationListSuccessAction = createAction(
  SuccessActionTypes.GET_APPLICATION_LIST_SUCCESS,
  props<{ payload: IApplicationList[]}>()
);

export const getApplicationByIdRequestAction = createAction(
  RequestActionTypes.GET_SELECTED_APPLICATION_REQUEST,
  props<{ id: string, magicToken?: string }>()
);

export const getApplicationByIdSuccessAction = createAction(
  SuccessActionTypes.GET_SELECTED_APPLICATION_SUCCESS,
  props<{ application: IApplicationDetails }>()
);

export const finalizeFullApplicationRequest = createAction(
  RequestActionTypes.FINALIZE_FULL_APPLICATION_REQUEST,
  props<{ id: string, application: IApplicationFinalize }>()
);

export const finalizeFullApplicationSuccess = createAction(
  SuccessActionTypes.FINALIZE_FULL_APPLICATION_SUCCESS,
  // props<{  }>()
);

export const saveFilesRequest = createAction(
  RequestActionTypes.SAVE_APPLICATION_FILES_REQUEST,
  props<{ requests: Array<Observable<any>>, applicationId: string }>()
);

export const saveFilesSuccess = createAction(
  SuccessActionTypes.SAVE_APPLICATION_FILES_SUCCESS,
  props<{ filesUploaded: boolean }>()
);

export const deleteFileRequest = createAction(
  RequestActionTypes.DELETE_APPLICATION_FILE_REQUEST,
  props<{applicationId: string, attachmentId: string}>()
);

export const deleteFileSuccess = createAction(
  SuccessActionTypes.DELETE_APPLICATION_FILE_SUCCESS,
  props<{attachmentId: string}>()
);

export const deleteFileFailure = createAction(
  FailureActionTypes.DELETE_APPLICATION_FILE_FAILURE,
  props<{error: any}>()
);

export const saveApplicationSectionRequest = createAction(
  RequestActionTypes.SAVE_APPLICATION_SECTION_REQUEST,
  props<{ data: any, applicationId: string, redirect?: any, updateState?: boolean }>()
);

export const saveApplicationSectionSuccess = createAction(
  SuccessActionTypes.SAVE_APPLICATION_SECTION_SUCCESS,
  props<{ data: any, redirect?: boolean, updateState?: boolean }>(),
);

export const saveApplicationSectionError = createAction(
  FailureActionTypes.SAVE_APPLICATION_SECTION_FAILURE,
  props<{ error: any }>(),
);
