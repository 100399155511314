import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './invitation-reducer';
import { InvitationStoreEffects } from './invitation-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('invitation', reducer),
    EffectsModule.forFeature([InvitationStoreEffects])
  ]
})
export class InvitationStoreModule { }
