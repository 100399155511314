import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ActiveProvider, QrAttributes } from 'src/app/shared/models/financial-session';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentlessService {
  private defaultQrData: QrAttributes = {
    bank: 'Other',
    oldBank: 'Other',
    isEmployed: false,
    isSelfEmployed: false,
    isRetired: false,
  };

  constructor(private apiService: ApiService) {
  }

  getQRCode(
    referenceId: string,
    withPartner: boolean,
    forPartner: boolean,
    candidateData: QrAttributes,
    applicationType?: string,
    magicToken?: string,
    partnerData?: QrAttributes,
  ): Observable<any> {

    let url = '/portal/financial-check/qr-code';
    if (magicToken) {
      url = url + `/${magicToken}`
    }

    return this.apiService.saveBlob('financial-check', url, {
      referenceId,
      withPartner,
      forPartner: !!forPartner,
      candidateData: { ...this.defaultQrData, ...candidateData },
      partnerData: forPartner ? partnerData : null,
      type: applicationType,
    });
  }

  getApplicationLink(
    referenceId: string,
    withPartner: boolean,
    forPartner: boolean,
    candidateData: QrAttributes,
    applicationType?: string,
    magicToken?: string,
    partnerData?: QrAttributes,
  ): Observable<any> {

    let url = '/portal/financial-check/application-link';
    if (magicToken) {
      url = url + `/${magicToken}`
    }
    return this.apiService.save('financial-check', url, {
      referenceId,
      withPartner,
      forPartner: !!forPartner,
      candidateData: { ...this.defaultQrData, ...candidateData },
      partnerData: forPartner ? partnerData : null,
      type: applicationType,
    });
  }

  getOcktoAvailability(magicToken: string) {
    if (magicToken) {
      return this.apiService.update('financial-check', `/portal/financial-check/check-availability/${magicToken}`);
    }
    return this.apiService.update('financial-check', `/portal/financial-check/check-availability`);
  }

  getSessionStatus(id: string, type: string, magicToken?: string) {
    if (magicToken) {
      return this.apiService.getSingle('financial-check', `/portal/financial-check-sessions/${id}/${type}/${magicToken}`);
    }
    return this.apiService.getSingle('financial-check', `/portal/financial-check-sessions/${id}/${type}`);
  }

  getActiveProviders(accountId: string): Observable<ActiveProvider> {
    return this.apiService.getSingle('financial-check', `/providers/active/${accountId}`).pipe(
      map(response => response.data),
    );
  }

  getActiveProvidersByToken(magicToken: string): Observable<ActiveProvider> {
    return this.apiService.getSingle('financial-check', `/providersByToken/active/${magicToken}`).pipe(
      map(response => response.data),
    );
  }

  convertToBase64FromBlob(blob) {
    const temporaryFileReader = new FileReader();
    temporaryFileReader.readAsDataURL(blob);
    return new Promise<string>((resolve, reject) => {
      temporaryFileReader.onloadend = () => {
        const dataURL: string = temporaryFileReader.result as string;
        resolve(`data:image/png;base64,${dataURL.split(',')[1]}`);
      };
    });
  }
}
