import { ActiveProvider, IFinancialSession } from 'src/app/shared/models/financial-session';

export interface DocumentlessState {
  activeProvider: ActiveProvider;
  activeProviderLoading: boolean;
  qrCodeLoading: boolean;
  qrCodeError: string | null;
  qrCodeImage: string;
  partnerQrCodeImage: string;
  applicationLinkLoading: boolean;
  applicationLinkError: string | null;
  applicationLink: string;
  partnerApplicationLink: string;
  isAvailable: boolean | null;
  isAvailableLoading: boolean;
  isAvailableError: any;
  financialSession: IFinancialSession | null;
  financialSessionError: string | null;
  financialSessionLoading: boolean;
  sessionNotInitiatedForCandidate: boolean;
  sessionCompletedOneTimeOnly: boolean;
}

export const initialState: DocumentlessState = {
  activeProvider: null,
  activeProviderLoading: false,
  qrCodeLoading: false,
  qrCodeError: null,
  qrCodeImage: '',
  partnerQrCodeImage: '',
  applicationLinkLoading: false,
  applicationLinkError: null,
  applicationLink: '',
  partnerApplicationLink: '',
  isAvailable: null,
  isAvailableLoading: false,
  isAvailableError: null,
  financialSession: null,
  financialSessionError: null,
  financialSessionLoading: true,
  sessionNotInitiatedForCandidate: false,
  sessionCompletedOneTimeOnly: false
};
