import { Action, createReducer, on } from '@ngrx/store';
import * as orgDetailsActions from './actions';
import { initialState, OrganizationDetailsState } from 'src/app/store/organization-store/org-details-state';

const organizationDetailsReducer = createReducer(
  initialState,
  on(orgDetailsActions.startAccountSettingsLoadingAction, state => ({...state, error: null, isLoading: true})),
  on(orgDetailsActions.finishAccountSettingsLoadingAction, state => ({...state, isLoading: false})),
  on(orgDetailsActions.setAccountSettingsErrorAction, (state, {payload}) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false
    };
  }),

  on(orgDetailsActions.getOrgDetailsRequest, state => ({...state, error: null, isLoading: true})),
  on(orgDetailsActions.getOrgDetailsForRootRequest, state => ({...state, error: null, isLoading: true})),
  on(orgDetailsActions.getPortalDomainsRequest, state => ({...state, error: null, domainsLoading: true})),
  on(orgDetailsActions.getOrgDetailsSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      details: action.payload
    };
  }),
  on(orgDetailsActions.getOrgDetailsFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(orgDetailsActions.getOrgDetailsForRootSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      details: action.payload
    };
  }),
  on(orgDetailsActions.getPortalDomainsSuccess, (state, action) => ({
    ...state,
    domainsLoading: false,
    error: null,
    domains: action.payload
  })),
  on(orgDetailsActions.getOrgDetailsForRootFailure, (state, action) =>
    ({
      ...state,
      isLoading: false,
      error: action.error
    })),
  on(orgDetailsActions.getPortalDomainsFailure, (state,action)=>({
    ...state,
    domainsLoading: false,
    error: action.error
  })),
  on(orgDetailsActions.getAccountSettingsSuccess, (state, {payload}) => ({ ...state, details: payload })),
  on(orgDetailsActions.clearAccountAfterLogout, (state, {}) => ({ ...initialState })),
);

export function reducer(state: OrganizationDetailsState | undefined, action: Action) {
  return organizationDetailsReducer(state, action);
}
