import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'osre-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() address: string;
  @Input() headerImage: string;

  constructor() { }

  ngOnInit() {
  }

}
