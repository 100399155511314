import { createAction, props } from '@ngrx/store';
import { RequestActionTypes, SuccessActionTypes, FailureActionTypes } from 'src/app/store/documentless-store/documentless-types';
import { QrAttributes } from 'src/app/shared/models/financial-session';

export const getApplicationLinkRequestAction = createAction(
  RequestActionTypes.GET_APPLICATION_LINK_REQUEST,
  props<{ id: string, withPartner: boolean, forPartner?: boolean, candidateData?: QrAttributes, applicationType?: string, magicToken?: string }>()
);

export const getApplicationLinkSuccessAction = createAction(
  SuccessActionTypes.GET_APPLICATION_LINK_SUCCESS,
  props<{ payload: string, forPartner: boolean }>()
);

export const getApplicationLinkFailureAction = createAction(
  FailureActionTypes.GET_APPLICATION_LINK_FAILURE,
  props<{ payload: { error: any }}>()
);

