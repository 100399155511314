export interface AuthState {
  user: any;
  isAuthenticated: boolean;
  error: any | null;
  isLoading: boolean;
  registered: boolean;
  activated: boolean;
  success: boolean; // password reset success
}

export const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  error: null,
  isLoading: false,
  registered: false,
  activated: false,
  success: null,
};
