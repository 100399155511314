import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './actions';
import { initialState, AuthState } from 'src/app/store/auth-store/auth.state';

const authReducer = createReducer(
  initialState,
  on(authActions.startAuthLoadingAction, state => ({...state, error: null, isLoading: true})),
  on(authActions.finishAuthLoadingAction, state => ({...state, isLoading: false})),
  on(authActions.setAuthErrorAction, (state, {payload}) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false
    };
  }),
  on(authActions.loginRequestAction, state => ({...state, error: null, isLoading: true})),
  on(authActions.loginSuccessAction, (state, action) => {
    return {
      ...state,
      user: action.payload.user,
      isAuthenticated: action.payload.isAuthenticated,
      isLoading: false,
      error: null
    };
  }),
  on(authActions.getUserDetailsRequestAction, state => ({...state, error: null, isLoading: true})),
  on(authActions.getUserDetailsSuccessAction, (state, action) => {
    return {
      ...state,
      user: action.payload.user,
      isAuthenticated: action.payload.isAuthenticated,
      isLoading: false,
      error: null
    };
  }),
  on(authActions.getUserDetailsFailedAction, (state, {error}) => ({
    ...state,
    error,
    isLoading: false,
  })),
  on(authActions.registerUserRequestAction, state => ({...state, error: null, isLoading: true, activated: false})),
  on(authActions.registerUserSuccessAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      registered: action.payload.registered
    };
  }),
  on(authActions.confirmUserRequestAction, state => ({...state, error: null, isLoading: true, activated: false})),
  on(authActions.confirmUserSuccessAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      activated: action.payload.activated
    };
  }),
  on(authActions.passwordResetRequestAction, state => ({...state, error: null, isLoading: true, success: false})),
  on(authActions.passwordChangeRequestAction, state => ({...state, error: null, isLoading: true, success: false})),
  on(authActions.passwordResetSuccessAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      success: action.success
    };
  }),
  on(authActions.passwordChangeSuccessAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      success: action.success
    };
  }),
  on(authActions.logoutSuccessAction, (state) => {
    return {
      ...initialState,
      isLoading: false,
    };
  }),
  on(authActions.getUserDetailsErrorAction, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
